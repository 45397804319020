<div
  [formGroup]="formGroup"
  class="form-group"
  [class.has-error]="!isValid(formGroup, ctrl)"
  [libCtrConfigUI]="ctrl"
  [altura]="ctrl?.Altura"
  [isMobile]="isMobile"
>
  <!-- [altura]="ALTURACONTROLE" -->
  <ng-container [ngSwitch]="radioType">
    <ng-container *ngSwitchCase="'YesNo'">
      <mat-slide-toggle
        #toggle
        labelPosition="before"
        [name]="getId(ctrl)"
        [tabIndex]="getTabIndex(ctrl)"
        (change)="onSlideChange($event, ctrl)"
        [disabled]="!ctrl?.IsEnable"
      >
        {{ ctrl?.Rotulo }}
      </mat-slide-toggle>
    </ng-container>

    <ng-container *ngSwitchCase="'Options'">
      <lib-title-label
        [formGroup]="formGroup"
        [isMobile]="isMobile"
        [enTheme]="enTheme"
        class="lbl"
        [ctrl]="ctrl"
        #lblTitle
        [class.title-mobile]="isMobile"
      >
      </lib-title-label>

      <mat-button-toggle-group
        [formControlName]="getId(ctrl)"
        name="fontStyle"
        aria-label="Font Style"
        style="width: max-content"
        (change)="onToggleChange($event, ctrl)"
      >
        <mat-button-toggle
          [class.black]="enTheme == ENTHEME.black"
          [class.is-desktop]="!isMobile"
          [value]="ctrl?.lstValue.string[0]"
          >{{ ctrl?.lstValue.string[0] }}</mat-button-toggle
        >
        <mat-button-toggle
          [class.black]="enTheme == ENTHEME.black"
          [class.is-desktop]="!isMobile"
          [value]="ctrl?.lstValue.string[1]"
          >{{ ctrl?.lstValue.string[1] }}</mat-button-toggle
        >
      </mat-button-toggle-group>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <lib-title-label
        [formGroup]="formGroup"
        [isMobile]="isMobile"
        [enTheme]="enTheme"
        class="lbl"
        [ctrl]="ctrl"
        #lblTitle
        [class.title-mobile]="isMobile"
      >
      </lib-title-label>

      <mat-radio-group
        [id]="getId(ctrl)"
        class="example-radio-group"
        [formControlName]="getId(ctrl)"
        [name]="getId(ctrl)"
        [tabIndex]="getTabIndex(ctrl)"
      >
        <mat-radio-button
          class="example-radio-button"
          [class.radioHide]="isEmpty(value)"
          [class.mobile]="isMobile"
          *ngFor="let value of ctrl.lstValue.string"
          [value]="value"
        >
          {{ value }}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </ng-container>
</div>
