<div
  [formGroup]="formGroup"
  class="form-group"
  [class.has-error]="!isValid(formGroup, ctrl)"
  [libCtrConfigUI]="ctrl"
  [altura]="ctrl?.Altura"
  [isMobile]="isMobile"
  [class.black]="enTheme === ENTHEME.black"
>
  <lib-title-label
    [formGroup]="formGroup"
    [isMobile]="isMobile"
    [enTheme]="enTheme"
    [ctrl]="ctrl"
    #lblTitle
  ></lib-title-label>
  <div
    #table
    class="table-responsive"
    [class.black]="enTheme === ENTHEME.black"
  >
    <table
      [id]="getId(ctrl)"
      mat-table
      [dataSource]="dataSource | libCtrlMatGridSearch: search"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef [style.width]="'60px'">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let row" [style.width]="'60px'">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Footer -->
      <ng-container matColumnDef="actionBar">
        <td
          #footer
          mat-footer-cell
          *matFooterCellDef
          [attr.colSpan]="
            getDisplay(displayedColumns, table.offsetWidth).length + 1
          "
        >
          <div class="flex-container">
            <div class="td-buttons">
              <button
                mat-raised-button
                color="primary"
                [class.btn-sm]="isMobile && table?.offsetWidth < 300"
                *ngIf="ctrl?.CanAddItem && !isReadOnly(ctrl)"
                (click)="
                  onNewItem(
                    $event,
                    ctrl.AtividadeNo,
                    ctrl.CadastroNo,
                    ctrl.CanAddInCadastro,
                    ctrl.VariavelNo
                  )
                "
              >
                <mat-icon>add</mat-icon>
                <ng-container *ngIf="!isMobile || table?.offsetWidth >= 300">{{
                  textBtnNew
                }}</ng-container>
              </button>

              <button
                mat-raised-button
                color="warn"
                [class.btn-sm]="isMobile && table?.offsetWidth < 300"
                *ngIf="ctrl?.CanExcludeItem && !isReadOnly(ctrl)"
                (click)="onDeleteItems($event, ctrl.CanAddInCadastro)"
              >
                <mat-icon>delete</mat-icon>
                <ng-container *ngIf="!isMobile || table?.offsetWidth >= 300">{{
                  textBtnDelete
                }}</ng-container>
              </button>
            </div>

            <div
              class="td-search"
              *ngIf="!isMobile || table.offsetWidth >= 350"
            >
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  class="search"
                  [value]="search"
                  #inputSearch
                  (keyup)="onSearchChange(inputSearch.value)"
                  (keyup.esc)="onSearchEsc($event)"
                />

                <button
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Procurar"
                  type="button"
                >
                  <mat-icon class="search-icn">search</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Generated Column -->
      <ng-container [matColumnDef]="col.ColumnName" *ngFor="let col of columns">
        <th mat-header-cell *matHeaderCellDef>{{ col.ColumnName }}</th>

        <td
          mat-cell
          *matCellDef="let item"
          (click)="
            onEditItem(
              $event,
              ctrl.AtividadeNo,
              ctrl.CadastroNo,
              item,
              ctrl.CanAddInCadastro,
              ctrl.VariavelNo,
              isReadOnly(ctrl),
              isReadOnlyGrid(ctrl)
            )
          "
        >
          {{ getValue(col, item) }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="getDisplay(displayedColumns, table.offsetWidth)"
      ></tr>
      <tr
        mat-row
        class="row"
        *matRowDef="
          let row;
          columns: getDisplay(displayedColumns, table.offsetWidth)
        "
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['actionBar']; sticky: true"></tr>
    </table>
  </div>
</div>
