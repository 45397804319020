import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFileUploadDialog } from '../../interface/IFileUploadDialog';
import { LogService, ConfigJsonService, IFileUploadResponse } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { EnFileUploadMode } from '../../enum/EnFileUploadMode';
import { IDocumento } from '@medlogic/shared/shared-interfaces';
import { error } from '../../service/error';
import { FileUploadService } from '../../service/file-upload.service';
import { tap } from 'rxjs/operators';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'lib-cmp-file-upload-selector',
  templateUrl: './cmp-file-upload-selector.component.html',
  styleUrls: ['./cmp-file-upload-selector.component.css']
})
export class CmpFileUploadSelectorComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() enFileUploadMode: EnFileUploadMode;
  @Input() data: IFileUploadDialog;
  @Input() acceptFiles = '.pdf, .xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .pps, .ppsx, .txt, .xml, .zip, .rar';

  @Output() afterFileUpload = new EventEmitter<IDocumento>();
  @Output() afterOpenWebcam = new EventEmitter<File>();
  @Output() afterChooseFile = new EventEmitter<boolean>();

  selectedDocument: IDocumento;
  fileToUpload: File = null;
  isLoading = false;
  progress: number;
  backgroundColor = 'red';

  constructor(
    private cnfJson: ConfigJsonService,
    private log: LogService,
    private glb: GlobalService,
    private fileUploadSrv: FileUploadService,
  ) {
    super();
  }

  ngOnInit() { }

  fileEvent: any = '';
  preview: any = '';
  fileName: string;

  onImgChange(event: any): void {
    const file = event.target.files[0];

    if (file.type.includes('image')) {
      this.fileEvent = event;
      this.fileName = event.target.files[0].name;
    } else {
      this.fileToUpload = file;
      this.uploadFileToActivity(file, this.data);
    }

    this.afterChooseFile.emit(true);
  }

  imageCrop(event: ImageCroppedEvent) {
    this.preview = event.base64;
  }

  showCropper() { }
  invokeCropper() { }
  error() { }

  base64ToBlob(base64: string, type: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: type });
  }

  uploadFile($event: any): void {
    try {
      if (this.preview) {
        const blob = this.base64ToBlob(this.preview, 'image/png');
        const fileToUpload = new File([blob], this.fileName, { type: blob.type, lastModified: Date.now() });

        this.fileToUpload = fileToUpload;
        this.uploadFileToActivity(fileToUpload, this.data);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'uploadFile', error.message);
    }
  }

  uploadFileFromWebcam($event: any): void {
    try {
      const fileName = $event.fileName;
      this.fileName = fileName;
      this.enFileUploadMode = this.data.enFileUploadMode;

      const fileToUpload = new File([$event.imageData], fileName, { type: 'image/png' });
      this.fileToUpload = fileToUpload;

      this.onImgChange({ target: { files: [fileToUpload] } });

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'uploadFileFromWebcam', error.message);
    }
  }

  onWebcamClick($event: any): void {
    try {
      this.fileToUpload = null;
      this.enFileUploadMode = EnFileUploadMode.webcam;

      this.afterOpenWebcam.emit();
      this.afterChooseFile.emit(true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onWebcam', error.message);
    }
  }

  onPreviewBack($event: any): void {
    try {
      this.selectedDocument = null;
      this.enFileUploadMode = EnFileUploadMode.list;
      this.fileEvent = null;
      this.preview = null;

      this.afterChooseFile.emit(false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPreviewBack', error.message);
    }
  }

  uploadFileToActivity($event: any, data: IFileUploadDialog): void {
    try {
      this.isLoading = true;

      const containerTag = `${this.cnfJson.project.toLowerCase()}`;
      const saveFileToStorage$ = this.fileUploadSrv.postFile(this.fileToUpload, data.ano, data.ono, data.vno, containerTag);

      this.subs.sink = saveFileToStorage$
        .pipe(
          this.finalizeAccordingFileUploadMode$(data.enFileUploadMode),
          error()
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'uploadFileToActivity', error.message);
    }
  }

  protected finalizeAccordingFileUploadMode$ = (enFileUploadMode: EnFileUploadMode) =>
    tap((res: IFileUploadResponse) => {
      this.isLoading = false;
      this.afterFileUpload.emit(res?.documento);
    })
}
