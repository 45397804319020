import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeFormRoutingModule, PageNotFoundViewComponent } from '@medlogic/shared/geform';

const appRoutes: Routes = [
  // {
  //   path: 'ge',
  //   loadChildren: () => import('./ge/app-ge-routing.module').then(m => m.AppGeRoutingModule)
  // },
  {
    // canActivateChild: [AuthChildGuardService],
    path: 'assist',
    loadChildren: () => import('@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full').then(m => m.AppAssistRoutingModule)
  },
  {
    // canActivateChild: [AuthChildGuardService],
    path: 'kitchen',
    loadChildren: () => import('@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full').then(m => m.AppKitchenRoutingModule)
  },
  {
    // canActivateChild: [AuthChildGuardService],
    path: 'pwa',
    loadChildren: () => import('@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full').then(m => m.AppPwaRoutingModule)
  },
  {
    path: 'util',
    loadChildren: () =>
      import('./modules/utility/utility.module').then((m) => m.UtilityModule),
  },
  // Default
  { path: '', redirectTo: 'pwa', pathMatch: 'prefix' },
  { path: '**', component: PageNotFoundViewComponent }
];

@NgModule({
  imports: [
    GeFormRoutingModule,
    RouterModule.forRoot(
      appRoutes,
      {
    enableTracing: false,
    useHash: true // adiciona # às rotas para dispensar url rewrite
    ,
    relativeLinkResolution: 'legacy'
}
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppMedlogicPwaCuidadoRoutingModule { }
