<div [formGroup]="formGroup" class="form-group" [class.has-error]="!isValid(formGroup, ctrl)" [libCtrConfigUI]="ctrl" [altura]="ctrl?.Altura" [isMobile]="isMobile">
  <!-- <lib-title-label [formGroup]="formGroup" [ctrl]="ctrl" #lblTitle></lib-title-label> -->
  <!-- (onChange)="onChange(ctrl.VariavelNo, image.src)"  -->
  <div class="control-label" *ngIf="ctrl?.ShowRotulo"
    [class.required]="ctrl?.RequiredField && !isValid(formGroup, ctrl)">
    {{ label }}
  </div>

  <ng-container [ngSwitch]="hasImage(ctrl)">

    <ng-container *ngSwitchCase="true">

      <img title="imagem" [id]="getId(ctrl)" *ngIf="hasImage(ctrl)" [src]="imgIdoso" [tabIndex]="getTabIndex(ctrl)"
        (onChange)="onChange()" #image [width]="220" [height]="290"
        (click)="onAttachment($event, isEditable)" />

      <button *ngIf="isEmpty(ctrl) && isEditable" mat-icon-button (click)="onAttachment($event, isEditable)"
        type="button">
        <mat-icon>camera_alt</mat-icon>{{ imgFileText }}
      </button>

    </ng-container>

    <ng-container *ngSwitchCase="false">

      <!-- Definir o type é importante pois se for submit disparará o evento submit em AtividadeComponent -->
      <button mat-button (click)="onAttachment($event, isEditable)" type="button">
        <!-- <h3 *ngIf="!isEmpty(ctrl)">{{ getDocName(ctrl.Rotulo) }}</h3> -->
        <mat-icon aria-hidden="false" aria-label="Document">add_a_photo</mat-icon>ANEXAR
      </button>

    </ng-container>

  </ng-container>

</div>