<div
  *ngIf="formGroup && componentes?.length > 0"
  [class.is-mobile]="isMobile"
  class="components"
>
  <div
    *ngFor="let ctrl of componentes"
    [class.mobile]="isMobile"
    [libCtrlIsVisible]="ctrl"
  >
    <ng-container *ngIf="ctrl && (!isMobile || ctrl.ShowInMobile)">
      <span
        *ngIf="isShowVariableNo && isMobile"
        class="badge badge-info"
        [libCtrPosition]="ctrl"
        [positionIsMobile]="isMobile"
        [deltaY]="10"
        height="21px"
        >{{ ctrl?.VariavelNo }}
      </span>

      <span
        *ngIf="isShowTabOrder"
        class="badge badge-warning"
        [libCtrPosition]="ctrl"
        [positionIsMobile]="isMobile"
        [deltaY]="10"
        [deltaX]="50"
        height="21px"
        >{{ ctrl?.TabOrder }}
      </span>

      <div [ngSwitch]="ctrl?.Type?.toUpperCase()">
        <div *ngSwitchCase="lib.CTRDATE">
          <lib-ctr-date
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-date>
        </div>
        <div *ngSwitchCase="lib.CTRLABEL">
          <lib-ctr-label
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-label>
        </div>
        <div *ngSwitchCase="lib.CTRTEXTBOXLABELED">
          <lib-ctr-textbox-label
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-textbox-label>
        </div>
        <div *ngSwitchCase="lib.CTRMULTILINETEXTBOXLABELED">
          <lib-ctr-multiline-textbox-label
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-multiline-textbox-label>
        </div>
        <div *ngSwitchCase="lib.CTRMULTILINETEXTBOXHTMLLABELED">
          <lib-ctr-multiline-textbox-htmllabel
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          >
          </lib-ctr-multiline-textbox-htmllabel>
        </div>
        <div *ngSwitchCase="lib.CTRIMAGELIST">
          <lib-ctr-image-player
            [attr.data-vno]="ctrl.VariavelNo"
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            (eventBubble)="onEventBubble($event)"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          >
          </lib-ctr-image-player>
        </div>
        <div *ngSwitchCase="lib.CTRRADIOBUTTON">
          <lib-ctr-radio-button
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-radio-button>
        </div>
        <div *ngSwitchCase="lib.CTRRATINGSTAR">
          <lib-ctr-rating-star
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-rating-star>
        </div>
        <div *ngSwitchCase="lib.CTRRATINGPROGRESS">
          <lib-ctr-rating-progress
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-rating-progress>
        </div>
        <div *ngSwitchCase="lib.CTRVIDEO">
          <lib-ctr-video
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-video>
        </div>
        <div *ngSwitchCase="lib.CTRMAPA">
          <lib-ctr-mapa
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
          ></lib-ctr-mapa>
        </div>
        <!--List Controls -->
        <div *ngSwitchCase="lib.CTRCOMBOBOX">
          <lib-ctr-combobox
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [id]="'ctrComboBox' + getId(ctrl)"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
            (onDataLoaded)="onDataLoaded($event)"
            (eventBubble)="onEventBubble($event)"
          ></lib-ctr-combobox>
        </div>
        <div *ngSwitchCase="lib.CTRCHECKBOXLIST">
          <lib-ctr-checkbox-list
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
            (onDataLoaded)="onDataLoaded($event)"
            (eventBubble)="onEventBubble($event)"
          ></lib-ctr-checkbox-list>
        </div>
        <div *ngSwitchCase="lib.CTRGRID">
          <lib-ctr-grid
            [formGroup]="formGroup"
            [enTheme]="enTheme"
            [libCtrPosition]="ctrl"
            [positionIsMobile]="isMobile"
            [isMobile]="isMobile"
            [ctrl]="ctrl"
            [isLoading]="isLoading"
            #ctrlField
            (onDataLoaded)="onDataLoaded($event)"
            (eventBubble)="onEventBubble($event)"
          ></lib-ctr-grid>
        </div>
        <div *ngSwitchDefault>
          <span [libCtrPosition]="ctrl" [positionIsMobile]="isMobile" #ctrlField
            >CONTROLE INEXISTENTE! {{ ctrl?.Type }}</span
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>
