import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable, NgModule } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, EMPTY, throwError } from 'rxjs';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { AppMedlogicPwaCuidadoState } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';

@Injectable()
export class MedlogicPwaCuidadoHttpRequestInterceptor implements HttpInterceptor {

    private readonly TOKEN_STR = '###TOKEN###';

    constructor(
        private store: Store<AppMedlogicPwaCuidadoState>,
        private log: LogService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        try {
            return of(req)
                .pipe(
                    withLatestFrom(this.store),
                    mergeMap(([req2, state]) => {
                        // if (req2 instanceof HttpResponse) {
                        // const token = this.cnf.baseUsuarioToken;
                        const token = state?.tenant?.token;
                        // Tenant key that will influence the cache key
                        const customerId = +state?.loginPWA?.selectedLogin?.customerId;
                        let reqWithUrl = req2.clone();
                        if (customerId && req2.method === 'GET') {
                            const separator = req2.url.indexOf('?') >= 0 ? '&' : '?';
                            reqWithUrl = req2.clone({
                                url: `${req2.url}${separator}cacheCustomerId=${customerId}` // A inclusão do usuário na chave do cache faria com que o cache fosse muito maior e um usuário não se beneficiaria da nutrição por outros. &cacheUserId=${state?.tenant?.login.usuarioLogadoNo}
                            });
                        }
                        if ((req2.method === 'GET' || req2.method === 'POST' || req2.method === 'PUT' || req2.method === 'DELETE') && (token)) {
                            const dupReq = reqWithUrl.clone({
                                setHeaders: { Authorization: `Bearer ${token}` }
                            });
                            return next.handle(dupReq);
                        }
                        return next.handle(req2);
                        // const dupReq = req2.clone({
                        //     headers: req2.headers.set('Authorization', `Bearer ${token}`),
                        // });
                        // if (!req2.params.keys().includes('token')) {
                        //     req2.params.append('token', token);
                        // }
                        // event = event.clone({
                        //     body: this.modifyBody(event?.body, token),
                        //     headers: dupReq.headers
                        // });
                        // }
                    }),
                    catchError((err) => {
                        const errorMsg = err.error.message || err.statusText;
                        console.log('error()', errorMsg);
                        return throwError(() => errorMsg);
                    })
                );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'intercept', error.message);
        }
        return next.handle(req);
    }

    private modifyBody(body: any, token: string): any {
        try {
            // return body.replace(this.TOKEN_STR, token);
            return body;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'modifyBody', error.message);
        }
    }


}


// tslint:disable-next-line: max-classes-per-file
@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MedlogicPwaCuidadoHttpRequestInterceptor,
            multi: true,
        },
    ],
})

export class MedlogicPwaCuidadoInterceptor { }
