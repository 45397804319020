<form
  #form
  name="form"
  [formGroup]="formGroup"
  *ngIf="actived"
  (ngSubmit)="onSubmit()"
>
  <lib-tab
    #tabComponent
    [activityType]="activityType"
    [saveInList]="saveInList"
    [addToHistory]="true"
    [componentes]="componentes"
    [atividade]="atividade"
    [tabs]="tabs"
    [isMobile]="isMobile"
    [tabActivedId]="tabActivedId"
    [enTheme]="enTheme"
    [saved]="saved"
    [formGroup]="formGroup"
    [formErrors]="formErrors"
    [isLoading]="isLoading | async"
    [atividadeNo]="atividadeNo"
    [atividadeNome]="atividadeNome"
    [ocorrenciaNo]="ocorrenciaNo"
    (eventBubble)="onEventBubble($event)"
    [isEditMode]="isEditMode"
    [isReadOnly]="isReadOnly"
  ></lib-tab>
</form>

<!--Mensagem de confirmação de exclusão -->
<p-confirmDialog
  header="Confirmação"
  icon="fa fa-question-circle"
  [rejectIcon]="message.icon"
  [rejectLabel]="message.firstButtonLabel"
  acceptLabel="Sim"
  [style]="{ width: '425px' }"
>
</p-confirmDialog>
