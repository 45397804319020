<ng-container [ngSwitch]="wasDeletedAndIsEmpty">
  <!--Apenas exibirá uma mensagem caso a Atividade atual tenha sido excluída e o histório de navegação esteja vazio. -->
  <div *ngSwitchCase="true">
    <div class="alert alert-success">
      <h1 class="success">O item foi excluído com sucesso!</h1>
    </div>
  </div>

  <div
    id="tab-component"
    [class.readOnly]="isReadOnly"
    [class.isMobile]="isMobile"
    [class.idoso]="idoso"
    [class.showOnlySaveButton]="showOnlySaveButton"
    *ngSwitchCase="false"
    [class.dark-mode]="enTheme === ENTHEME.black"
  >
    <lib-button-bar
      [isReadOnly]="isReadOnly"
      [activityType]="activityType"
      [atividade]="atividade"
      [isLoading]="isLoading"
      [isMobile]="isMobile"
      [formGroup]="formGroup"
      [saveInList]="saveInList"
      [saved]="saved"
      [canShowSavedMessages]="canShowSavedMessages"
      [addToHistory]="addToHistory"
      [formErrors]="formErrors"
      [componentes]="componentes"
      [tarefaNo]="tarefaNo"
      [msgs]="msgs"
      [enTheme]="enTheme"
      [printOnly]="printOnly"
      [isEditMode]="isEditMode"
      (eventBubble)="onEventBubble($event)"
      [showBackButton]="showBackButton"
    ></lib-button-bar>

    <div
      class="tabs-content"
      [class.tabs-content-mobile]="isMobile"
      [class.dark-mode]="enTheme === ENTHEME.black"
      [class.debug]="isDebug"
    >
      <!-- Exibição Desktop -->

      <ng-container *ngIf="!isMobile">
        <!-- Nomes das Abas -->
        <mat-tab-group class="nav nav-tabs form-group">
          <ng-container *ngFor="let tab of tabs | libVisibleTabs: componentes">
            <mat-tab
              *ngIf="tab.enabled"
              [label]="tab.name"
              [class.has-error]="
                !validateTabComponents(tab.id, componentes, formGroup)
              "
            >
              <div
                [style.zoom]="getZoom()"
                [style.height]="getHeight()"
                [class.dark-mode-tab]="enTheme === ENTHEME.black"
                class="tab-desktop-content"
              >
                <lib-atividade
                  *ngIf="formGroup && componentes.length > 0"
                  [tab]="tab"
                  [componentes]="componentes | libFilterComponentesByTab: tab"
                  [componentesAllTabs]="
                    componentes | libFilterComponentesByTab: tab
                  "
                  [formErrors]="formErrors"
                  [formGroup]="formGroup"
                  (errorNotify)="errorNotify($event)"
                  (changesNotify)="changesNotify($event)"
                  (eventBubble)="onEventBubble($event)"
                  [isLoading]="isLoading"
                  [enTheme]="enTheme"
                ></lib-atividade>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </ng-container>

      <!-- Exibição Mobile -->

      <ng-container *ngIf="isMobile">
        <ng-container>
          <div class="tab-content">
            <div
              *ngFor="let tab2 of tabs | libVisibleTabs: componentes"
              [id]="'tab-' + tab2.id"
              class="tab-pane"
              [class.active]="isActived(tab2)"
            >
              <div
                class="panel-body is-mobile"
                [class.read-only]="isReadOnly"
                [class.dark-mode-card]="enTheme === ENTHEME.black"
              >
                <label
                  class="categ-title"
                  [class.dark-mode-title]="enTheme === ENTHEME.black"
                  >{{ tab2.name }}</label
                >

                <lib-atividade
                  *ngIf="formGroup && componentes"
                  [tab]="tab2"
                  [componentes]="componentes | libFilterComponentesByTab: tab2"
                  [componentesAllTabs]="componentes"
                  [formErrors]="formErrors"
                  [formGroup]="formGroup"
                  (errorNotify)="errorNotify($event)"
                  (changesNotify)="changesNotify($event)"
                  (eventBubble)="onEventBubble($event)"
                  [isMobile]="isMobile"
                  [isLoading]="isLoading"
                  [enTheme]="enTheme"
                ></lib-atividade>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <!-- Barra de botões -->
    <div
      #btnbar
      class="btnbar"
      [class.read-only]="isReadOnly"
      [class.is-mobile]="isMobile"
      [class.is-desktop]="!isMobile"
      [class.navbar-dialog]="isDialog"
      [class.dark-mode]="enTheme === ENTHEME.black"
      [ngSwitch]="activityType"
    >
      <!-- StandAlone, usado no app ou acesso direto via web  -->
      <div *ngSwitchCase="enActivityType.StandAlone">
        <lib-next-action-bar
          [activityType]="activityType"
          [atividade]="atividade"
          [formGroup]="formGroup"
          [saveInList]="saveInList"
          [saved]="saved"
          [addToHistory]="addToHistory"
          [formErrors]="formErrors"
          [componentes]="componentes"
          [tabs]="tabs"
          [isMobile]="isMobile"
          [tabActivedId]="tabActivedId"
          [isLoading]="isLoading"
          [tarefaNo]="tarefaNo"
          [msgs]="msgs"
          [enTheme]="enTheme"
          (eventBubble)="onEventBubble($event)"
        >
        </lib-next-action-bar>
      </div>

      <!-- Desktop -->
      <div *ngSwitchDefault [class]="">
        <lib-action-bar
          [activityType]="activityType"
          [isEdit]="isEditMode"
          [atividade]="atividade"
          [isLoading]="isLoading"
          [isMobile]="isMobile"
          [formGroup]="formGroup"
          [saveInList]="saveInList"
          [saved]="saved"
          [canShowSavedMessages]="canShowSavedMessages"
          [addToHistory]="addToHistory"
          [formErrors]="formErrors"
          [componentes]="componentes"
          [tarefaNo]="tarefaNo"
          [msgs]="msgs"
          [enTheme]="enTheme"
          (eventBubble)="onEventBubble($event)"
        ></lib-action-bar>

        <lib-status-bar
          [backAtividadeNo]="backAtividadeNo"
          [backOcorrenciaNo]="backOcorrenciaNo"
          [isMobile]="isMobile"
          [ocorrenciaNo]="ocorrenciaNo"
          [enTheme]="enTheme"
          [atividadeNome]="atividadeNome"
          [atividadeNo]="atividadeNo"
          (eventBubble)="onEventBubble($event)"
        ></lib-status-bar>
      </div>
    </div>
  </div>
</ng-container>
