import { Injectable } from '@angular/core';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { LogService, ConfigJsonService, routeGetFormByName } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { of } from 'rxjs';
import { EnContentType } from '../enum/en-content-type.enum';

@Injectable()
export class CadConfigService {
  urlGet_WEBSERVICE = 'Form/GetFormByName?cadastroNome={0}';
  urlGet = `${routeGetFormByName}?cadastroNome={0}`;
  cadTenantConfig = 'CAD - CONFIG IDOSO BEM CUIDADO';
  dtInicial: any;
  dtFinal: any;

  constructor(
    private basepage: BasePageService,
    cfgJson: ConfigJsonService,
    private log: LogService) {
    this.cadTenantConfig = cfgJson.cadConfigName || this.cadTenantConfig;
  }

  /**
    * Retorna o número do cadastro escolhido pelo nome.
    * Utiliza a API NODEJS
    */
  getCadTenantConfig(): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGet, this.cadTenantConfig);
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {}, 3, EnContentType.Json);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadTenantConfig', error.message);
    }
    return of(null);
  }

  /**
    * Retorna o número do cadastro escolhido pelo nome.
    * Formato anterior de chamda do webservice.
    */
  getCadIdosoBemCuidado(): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGet_WEBSERVICE, this.cadTenantConfig);
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {}, 3, EnContentType.Json);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadIdosoBemCuidado', error.message);
    }
    return of(null);
  }
}
