import { ConfigStateService } from '@medlogic/shared/state-config';
import { LibService } from '../../../shared/service/lib.service';
import { IBubble } from '../../../shared/interface/ibubble';
import { ValidatorService } from '../../../shared/service/validator.service';
import { CalculadoraConditionService } from '../../../shared/service/calculadora-condition.service';
import { CalculadoraService } from '../../../shared/service/calculadora.service';
import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { of, Subject } from 'rxjs';
import { EnMessageSeverity } from '@medlogic/shared/gecore';
import { IMessage } from '@medlogic/shared/gecore';
import { UnsubscribeOnDestroyAdapter, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { IBasic } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { flatMap, mergeMap, tap } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-atividade',
  templateUrl: './atividade.component.html',
  styleUrls: ['./atividade.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtividadeComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() tab: IBasic;
  @Input() componentes: IAtividadeComponenteDAL[]; // Lista de componentes da mesma aba
  @Input() componentesAllTabs: IAtividadeComponenteDAL[]; // Lista de todos os componentes
  @Input() formErrors: any;
  @Input() formGroup: FormGroup;
  @Input() isMobile = false;
  @Input() isLoading = false;
  @Input() enTheme = EnTheme.black;

  @Output() eventBubble: EventEmitter<IBubble> = new EventEmitter<IBubble>();
  @Output() errorNotify: EventEmitter<IMessage> = new EventEmitter<IMessage>();
  @Output() changesNotify: EventEmitter<IAtividadeComponenteDAL> = new EventEmitter<IAtividadeComponenteDAL>();

  formGroupOldValues: { [key: string]: any } = {};
  isRecalculating = false;
  isFirstLoading = true;
  hasConfirmButton = true;
  wasLoaded = false;

  message: any = {
    firstButtonLabel: 'Não',
    title: 'Confirmação',
    icon: 'fa-times',
    text: '',
    acceptFunc: () => { } // método de exclusão
  };

  public get isShowVariableNo(): boolean {
    return this.cnfJson.showVariavelNo;
  }

  public get isShowTabOrder(): boolean {
    return this.cnfJson.showTabOrder;
  }

  protected cachedDependentCtrl: { variavelNo: number; ctrls: IAtividadeComponenteDAL[] }[] = [];
  protected cachedValidationDependentCtrl: { variavelNo: number; ctrls: IAtividadeComponenteDAL[] }[] = [];

  onEventBubble($event: IBubble) {
    this.eventBubble.emit($event);
  }

  constructor(
    private log: LogService,
    public config: ConfigStateService,
    public cnfJson: ConfigJsonService,
    private global: GlobalService,
    private calc: CalculadoraService,
    private calcCond: CalculadoraConditionService,
    private validator: ValidatorService,
    public lib: LibService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.subscribeForAsyncValue(this.componentesAllTabs, this.formGroup);
      this.subscribeFormChanges();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Se o campo for o Id de um Cadastro, preencherá o valor com o número da Ocorrencia criada. */
  protected fillId(formGroup: FormGroup, componentes: IAtividadeComponenteDAL[]): void {
    try {
      if (this.config.OcorrenciaNo.value && this.config.OcorrenciaNo.value > 0) {
        componentes.every((c) => {
          try {
            if (c.TypeRegister === 1) {
              c.ValorTexto = this.config.OcorrenciaNo.value;
              formGroup.get(this.lib.getId(c.VariavelNo)).markAsDirty();
              return true;
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'fillId.fillId', error.message);
          }
          return false;
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillId', error.message);
    }
  }

  /* Retorna uma lista de todos os controles que foram referenciado em alguma fórmula. */
  protected getAllCtrlReferenced(componentes: IAtividadeComponenteDAL[]): IAtividadeComponenteDAL[] {
    try {
      let referenced = new Array<IAtividadeComponenteDAL>();
      componentes.forEach((f) => {
        try {
          const ctrls = this.getListOfReferenciados(f);
          referenced = referenced.concat(ctrls);
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getAllCtrlReferenced.forEach', error.message);
        }
      });
      return this.global.Distinct(referenced);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAllCtrlReferenced', error.message);
    }
    return null;
  }

  /**
   * Subscreve-se a todos os controles que possuem AsyncValue, ou seja, controles que são carregados de forma assíncrona.
   * @param componentesAllTabs
   * @param fg
   */
  subscribeForAsyncValue(componentesAllTabs: IAtividadeComponenteDAL[], fg: FormGroup): void {
    try {
      componentesAllTabs.forEach((ctrl) => {
        try {
          if (!ctrl.AsyncValue) {
            ctrl.AsyncValue = new Subject();
          }
          const scopedCtrl = ctrl;

          this.subs.sink = ctrl.AsyncValue.subscribe((newValue) => {
            try {
              const field = fg.get(this.getId(scopedCtrl));
              this.onChangeRecursive(field, this.getId(ctrl));
              field.markAsDirty();
              field.patchValue(newValue, { onlySelf: false, emitEvent: false });
              field?.setValidators(this.validator.getValidators(ctrl).map<ValidatorFn>((m) => m.validator));
            } catch (error) {
              this.log.Registrar(
                this.constructor.name,
                'subscribeForAsyncValue.subscribe',
                error.message
              );
            }
          });
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'subscribeForAsyncValue', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'subscribeForAsyncValue', error.message);
    }
  }

  /**
   * Subscreve-se às alterações do formulário. Qualquer mudança aciona esse método, mesmo que não
   * tenha qualquer tipo de tratamento de evento change nos controls
   * é possível ter vários subscribers, em vários locais do código para capturar essas mudanças.
   */
  subscribeFormChanges(): void {
    try {
      const formControls = this.formGroup.getRawValue();
      const changeRecursive$ = (control: AbstractControl, clm: string, fg: FormGroup, componentesAllTabs: IAtividadeComponenteDAL[], forceChange: boolean) => tap(() => {
        this.onChangeRecursive(control, clm, forceChange);
        control?.markAsPristine();
        fg.markAsUntouched();
        this.checkValidationOfValidationDependents(clm, fg, componentesAllTabs);
      })

      // Pipe principal
      this.subs.sink = of(Object.keys(formControls))
        .pipe(
          flatMap(clm => clm),
          mergeMap(clm => {
            const control = this.formGroup.get(clm);
            return control?.valueChanges
              .pipe(
                changeRecursive$(control, clm, this.formGroup, this.componentes, false)
              )
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'subscribeFormChanges', error.message);
    }
  }

  /**
   * Verifica se o valor armazenado no controle mudou.
   * Se o valor não estiver registrado na propriedade de controle, fará o registro, e/ou atualização do valor.
  */
  protected wasChanged(clm: string, control: AbstractControl): boolean {
    try {
      const oldValue = this.formGroupOldValues[clm];
      let wasChanged = false;

      if (oldValue) {
        wasChanged = !this.global.isEqual(oldValue, control?.value);
        this.formGroupOldValues[clm] = control?.value;
        return wasChanged;
      } else {
        this.formGroupOldValues[clm] = control?.value;
        return true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'wasChanged', error.message);
    }
    return false;
  }

  /**
   * Verifica os controles que possuem alerta ou validação por fórmula, que dependem (fazem referência) do controle que se modificou.
   * Tem uma lógica parecida com onChangeRecursive, no entanto, não precisa ser recursivo, pois, a validação não modifica o valor do controle validado e, portanto,
   * não gera impacto para controles que dependam desse último.
   * É necessário estar separado do onChangeRecursive, pois, os validadores fazem, normalmente, em suas
   * fórmulas referência ao próprio controle, o que geraria loop infinito em onChangeRecursive.
   * ATENÇÃO: esse método precisa ser executado após o onChangeRecursive, pois, depende que a atualização
   * dos valores dos controles alterados pelos usuários, mas também os dependentes, tenham sido modificados.
  */
  protected checkValidationOfValidationDependents(
    ctrlId: string,
    formGroup: FormGroup,
    componentesAllTabs: IAtividadeComponenteDAL[]
  ): void {
    try {
      const dependents = this.getListOfValidationDependents(componentesAllTabs, ctrlId);
      dependents.forEach((d) => {
        const control = formGroup.get(this.lib.getId(d.VariavelNo));
        control?.setValidators(this.validator.getValidators(d).map<ValidatorFn>((m) => m.validator));

        try {
          control?.updateValueAndValidity();
        } catch (error) {
          this.log.Registrar(
            this.constructor.name,
            `checkValidationOfValidationDependents.dependents.forEach: ${ctrlId}`,
            error.message
          );
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'checkValidationOfValidationDependents', error.message);
    }
  }

  /**
   * Método recursivo para, a partir do controle que foi alterado pelo usuário, modificar todos os demais dependentes.
   * É muito importante que a primeira chamada tenha sido testada se o controle foi "touched (dirty)".
   * forceChange fará com que o valor seja modificado independente se está vazio ou considerado inalterado.
   * Útil, por exemplo, para forçar a mudança de valor no caso do primeiro carregamento de uma dropdown.
  */
  protected onChangeRecursive(
    changedControl: any,
    clm: string,
    forceChange: boolean = false
  ): void {
    let variavelNo = -1;
    try {
      let dependents: IAtividadeComponenteDAL[];
      variavelNo = this.lib.getVariavelNoFromId(clm);

      const value = changedControl.value;
      dependents = this.updateValorTextoERetornaListaDosDependentes(
        this.componentesAllTabs,
        variavelNo,
        value,
        forceChange
      );

      if (dependents && dependents.length > 0) {
        this.debugShowDependenceList(clm, dependents);
        dependents.forEach((d) => {
          let newClm;
          try {
            newClm = this.lib.getId(d.VariavelNo);
            const isVisible = this.calcCond.isVisibleCtrl(d);
            d.IsVisible = isVisible;
            const isReadOnly = this.calcCond.isReadOnly(d);
            d.IsEnable = !isReadOnly;
            const control = this.formGroup.get(newClm);

            if (isReadOnly) {
              control?.disable({ onlySelf: true, emitEvent: false });
            } else {
              control?.enable({ onlySelf: true, emitEvent: false });
            }

            this.notifyChangesToTab(d);
            const calculatedValue = this.getCalculatedValue(value, d) || '';

            if ((calculatedValue !== this.global.NAO_RECALCULAR_ITEM) && d.hasValueDependence) {
              control?.patchValue(calculatedValue, { emitEvent: false });
            }

            control?.setValidators(this.validator.getValidators(d).map<ValidatorFn>((m) => m.validator));
            this.onChangeRecursive(control, newClm);
          } catch (error) {
            this.log.Registrar(
              this.constructor.name,
              'onChangeRecursive.dependents.forEach',
              error.message + 'Id:' + newClm
            );
          }
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onChangeRecursive', error.message);
    }
  }

  /* Exibe uma lista de dependências que auxiliará a debugar as fórmulas  */
  protected debugShowDependenceList(clm: string, dependents: IAtividadeComponenteDAL[]): void {
    try {
      const deps = dependents.map((d) => d.VariavelNo.toString()).join(', ');
      const strDep = `Variavel: ${clm} Dependentes: ${deps}`;
      this.showDebug(strDep);

      if (this.cnfJson.showFormulasDependenceList) {
        this.errorNotify.emit({
          severity: EnMessageSeverity.warning,
          summary: 'Debug Fórmulas',
          detail: strDep
        } as IMessage);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDependenceList', error.message);
    }
  }

  /* Propaga uma mensagem de debug, mas condicionada às configurações de exibição ou não de debug.  */
  protected showDebug(msg: string): void {
    try {
      this.eventBubble.emit({
        bubbleEvent: EnBubbleEvent.debug,
        params: { msg }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDebug', error.message);
    }
  }


  /**
   * Atualiza os itens de um controle Grid com os valores de outros controles que são referenciados por fórmula.
   */
  protected updateGridItems(dependentes: IAtividadeComponenteDAL[], componentes: IAtividadeComponenteDAL[]): void {
    try {
      dependentes.forEach((d) => {
        try {
          const referenciados = this.getListOfReferenciados(d);
          referenciados.forEach((r) => {
            try {
              if (
                r.Type.toUpperCase() === this.lib.CTRGRID ||
                r.Type.toUpperCase() === this.lib.CTRCOMBOBOX
              ) {
                const find = componentes.find((f) => f.VariavelNo === r.VariavelNo);
                if (find) {
                  find.lstCadastroAdicional = r.lstCadastroAdicional;
                }
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'udateGridItems.forEach.forEach', error.message);
            }
          });
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'updateGridItems.forEach', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateGridItems', error.message);
    }
  }

  /**
   * O valor calculado dependede das listas de controles referenciados presentes na estrutura de cada componente
   * Nesse método, são identificados todos os controles que tiveram o valor alterado, depois, retorna-se uma lista
   * de todos os controles que dependem do valor do componente modificado.
   * Também faz a modificação do ValorTexto e Valor do componente, que serão necessários em visibilidade condicional e salvamento.
   * Se for um componente Grid, preenche a lstCadastroAdicional necessário para fórmulas de Grid.
   */
  protected updateValorTextoERetornaListaDosDependentes(
    componentes: IAtividadeComponenteDAL[],
    variavelNo: number,
    value: any,
    forceChange: boolean = false
  ): IAtividadeComponenteDAL[] {
    try {
      const ctrlToBeChanged = componentes.find((f) => f.VariavelNo === variavelNo);
      if (ctrlToBeChanged) {
        if (
          forceChange ||
          (ctrlToBeChanged.ValorTexto !== value &&
            (ctrlToBeChanged.Valor === undefined || ctrlToBeChanged.Valor !== value))
        ) {
          this.lib.setGEValue(value, ctrlToBeChanged);
          const dependents = this.getListOfDependentCtrl(componentes, ctrlToBeChanged);

          return dependents;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateValorTextoComponenteModificado', error.message);
    }
    return null;
  }

  /* Exibe a mensagem na tela. */
  protected showMessage(msg: IMessage): void {
    try {
      this.errorNotify.emit(msg);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showMessage', error.message);
    }
  }

  /**
   * Gera uma lista de controles que dependem do refCtrl
   * ATENÇÃO: lstControlesReferenciadosCustomValidation e lstControlesReferenciadosCustomAlert NÃO devem
   * ser relacionados, primeiro porque as validações são calculadas em outro lugar e depois porque será comum
   * que o controle nessas listas se referencie a si mesmo, pois, são regras de validação do controle.
   */
  protected getListOfDependentCtrl(
    componentes: IAtividadeComponenteDAL[],
    refCtrl: IAtividadeComponenteDAL
  ): IAtividadeComponenteDAL[] {
    try {
      const findFromCache = this.cachedDependentCtrl
        ? this.cachedDependentCtrl.find((f) => f.variavelNo === refCtrl.VariavelNo)
        : null;
      if (findFromCache) {
        return findFromCache.ctrls;
      } else {
        componentes
          .filter(c => this.someCtrlHasVariable(c.lstControlesReferenciadosPorFormula, refCtrl.VariavelNo))
          .forEach(e => e.hasValueDependence = true);
        const ctrlsThanContainVno = this.lstCtrlsThatContainVariable(componentes, refCtrl.VariavelNo);
        if (ctrlsThanContainVno && ctrlsThanContainVno.length > 0 && !this.isRecursiveDependence(refCtrl, ctrlsThanContainVno)) {
          this.cachedDependentCtrl.push({ variavelNo: refCtrl.VariavelNo, ctrls: ctrlsThanContainVno });
        }
        return ctrlsThanContainVno;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getListOfDependentCtrl', error.message);
    }
    return null;
  }

  private lstCtrlsThatContainVariable(ctrls: IAtividadeComponenteDAL[], variavelNo: number): IAtividadeComponenteDAL[] {
    try {
      return ctrls.filter((c) => this.someCtrlHasVariable(c.lstControlesReferenciadosPorFormula, variavelNo) ||
        this.someCtrlHasVariable(c.lstControlesReferenciadosReadOnly, variavelNo) ||
        this.someCtrlHasVariable(c.lstControlesReferenciadosVisibility, variavelNo));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'lstCtrlsThatContainRefCtrl', error.message);
    }
    return [];
  }

  /* O refCtrl não pode depender de nenhum dos dependentCtrls, pois geraria loop infinito. */
  isRecursiveDependence(
    refCtrl: IAtividadeComponenteDAL,
    dependentCtrls: IAtividadeComponenteDAL[]
  ): boolean {
    try {
      if (!dependentCtrls || dependentCtrls.length <= 0) {
        return false;
      }

      const res = dependentCtrls
        .reduce((isRecursive, ctrl) =>
          isRecursive && this.lstCtrlsThatContainVariable([refCtrl], ctrl.VariavelNo).length > 0
          , true);

      if (res) {
        console.log(`ATENÇÃO: dependência recursiva para: ${refCtrl.VariavelNo}, ${refCtrl.Rotulo || ''}`);
      }
      return res;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isNotRecursiveDependence', error.message);
    }
    return false;
  }

  /* Gera uma lista dos controles que possuem fórmulas de validação (CustomValidation) e/ou alerta */
  protected getListOfValidationDependents(
    componentes: IAtividadeComponenteDAL[],
    ctrlId: string
  ): IAtividadeComponenteDAL[] {
    try {
      const findFromCache = this.cachedValidationDependentCtrl
        ? this.cachedValidationDependentCtrl.find((f) => this.global.isEqual(f.variavelNo, ctrlId))
        : null;

      if (findFromCache) {
        return findFromCache.ctrls;
      } else {
        const filtered = componentes.filter((c) =>
          (c.lstControlesReferenciadosCustomAlert &&
            c.lstControlesReferenciadosCustomAlert.length > 0 &&
            this.hasControl(c.lstControlesReferenciadosCustomAlert, ctrlId)) ||
          (c.lstControlesReferenciadosCustomValidation &&
            c.lstControlesReferenciadosCustomValidation.length > 0 &&
            this.hasControl(c.lstControlesReferenciadosCustomValidation, ctrlId))
        );
        this.cachedValidationDependentCtrl.push({ variavelNo: parseInt(ctrlId, 10), ctrls: filtered });
        return filtered;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getListOfValidationDependents', error.message);
    }
    return [];
  }

  /**
   * Retorna uma lista concatenada de todos os controles referenciados pelo ctrl.
   */
  protected getListOfReferenciados(ctrl: IAtividadeComponenteDAL): IAtividadeComponenteDAL[] {
    try {
      let references = new Array<IAtividadeComponenteDAL>();

      if (ctrl.lstControlesReferenciadosCustomAlert) {
        references = references.concat(ctrl.lstControlesReferenciadosCustomAlert);
      }
      if (ctrl.lstControlesReferenciadosCustomValidation) {
        references = references.concat(ctrl.lstControlesReferenciadosCustomValidation);
      }
      if (ctrl.lstControlesReferenciadosPorFormula) {
        references = references.concat(ctrl.lstControlesReferenciadosPorFormula);
      }
      if (ctrl.lstControlesReferenciadosReadOnly) {
        references = references.concat(ctrl.lstControlesReferenciadosReadOnly);
      }
      if (ctrl.lstControlesReferenciadosVisibility) {
        references = references.concat(ctrl.lstControlesReferenciadosVisibility);
      }
      return references;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getListOfDependentCtrl', error.message);
    }
    return [];
  }

  /* Identifica se um ou mais componentes tem a mesma variavelNo fornecida. */
  protected someCtrlHasVariable(componentes: IAtividadeComponenteDAL[], variavelNo: number): boolean {
    try {
      return !componentes ? false : componentes.findIndex((f) => f.VariavelNo === variavelNo) >= 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasCtrl', error.message);
    }
    return false;
  }

  /* Identifica se refControl está presente em componentes comparando VariavelNo */
  protected hasControl(componentes: IAtividadeComponenteDAL[], ctrlId: string): boolean {
    try {
      let has = false;
      has = !componentes
        ? false
        : componentes.findIndex((f) => f.VariavelNo === this.lib.getVariavelNoFromId(ctrlId)) >= 0;
      if (has) {
        return true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasCtrl', error.message);
    }
    return false;
  }

  /**
   * Além de retornar o valor, compara com o valor atual e dispara um novo change se diferente.
   * Há um hash que controla se houve modificação nos valores de um ou mais controles referenciados por fórmula.
   * Se não houver mudança, retornará o currentValue.
   */
  getCalculatedValue(currentValue: string, ctrl: IAtividadeComponenteDAL): string {
    try {
      const valorOuFormula = this.calc.getValorOuFormula(ctrl, currentValue);
      const newValue = this.calc.calculate(ctrl, ctrl.lstControlesReferenciadosPorFormula, valorOuFormula);

      return this.lib.getGEValue(newValue, ctrl, null, 'en-US');
    } catch (error) {
      if (ctrl) {
        const strError = `erro: ${error.message} formula:${currentValue} variavelNo: ${ctrl.VariavelNo} rotulo: ${ctrl.Rotulo}`;
        this.log.Registrar(this.constructor.name, 'getCalculatedValue', strError);
        this.errorNotify.emit({
          severity: EnMessageSeverity.error,
          summary: 'Erro de fórmula',
          detail: strError
        } as IMessage);
      } else {
        this.log.Registrar(this.constructor.name, 'getCalculatedValue', `erro: ${error.message} `);
      }
    }
    return currentValue;
  }

  /* retorna um id para o controle baseado na variavelNo */
  getId(ctrl: IAtividadeComponenteDAL): string {
    try {
      return this.lib.getId(ctrl?.VariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getId', error.message);
    }
    return '';
  }

  /* Necessário comunicar as mudanças para o Tab para controle de visibilidade das abas. */
  protected notifyChangesToTab(ctrl: IAtividadeComponenteDAL): void {
    try {
      this.changesNotify.emit(ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'notifyChangesToTab', error.message);
    }
  }

  /**
   * Chamado pelos controles do tipo ListControl para notificar que o dado terminou de carregar.
   * Será disparado o evento que recalcula os controles dependentes.
   * O Grid necessita notificar, pois, as fórmulas de grid são baseados nos itens do Grid.
   * A Combobox não precisaria, pois, o valor inicial do controle não depende do carregamento dos itens.
   * No entanto, a fórmula de proc, por exemplo, depende do item carregado.
  */
  onDataLoaded(changedCtrl: IAtividadeComponenteDAL | any): void {
    try {
      const clm = this.lib.getId(changedCtrl.VariavelNo);
      const control = this.formGroup.get(clm);
      this.onChangeRecursive(control, clm, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDataLoaded', error.message);
    }
  }
}
