import { IAlertMessage } from './../../../shared/interface/ialert-message';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { IAtividade } from '../../../shared/interface/iatividade';
import { LibService } from '../../../shared/service/lib.service';
import { IBubble } from '../../../shared/interface/ibubble';
import { FormGroup } from '@angular/forms';
import { AtividadeComponent } from '../atividade/atividade.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { EnActivityType } from '@medlogic/shared/gecore';
import { IBasic, ConfigJsonService, IAtividadeComponenteDAL, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { IMessage } from '@medlogic/shared/gecore';
import { EnTheme } from '@medlogic/shared/shared-interfaces';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() componentes: IAtividadeComponenteDAL[];
  @Input() atividade: IAtividade;
  @Input() formGroup: FormGroup;
  @Input() formErrors: any;
  @Input() saved: boolean;
  @Input() canShowSavedMessages: boolean;
  @Input() tabs: IBasic[];
  @Input() tabActivedId: number;
  @Input() isMobile: boolean;
  @Input() isAndroid: boolean;
  @Input() activityType: EnActivityType;
  @Input() saveInList: boolean;
  @Input() addToHistory: boolean;
  @Input() isActionBarVisible = true;
  @Input() tarefaNo: number;
  @Input() backAtividadeNo: number;
  @Input() backOcorrenciaNo: number;
  @Input() ocorrenciaNo: number;
  @Input() atividadeNome: string;
  @Input() atividadeNo: number;
  @Input() isLoading = false;
  @Input() enTheme = EnTheme.black;
  @Input() printOnly: string[]; // Se preenchido, somente listará para impressão os documentos com o título especificado
  @Input() isEditMode: boolean;
  @Input() isReadOnly: boolean = false;
  @Input() showBackButton = true;

  @Output() eventBubble: EventEmitter<IBubble> = new EventEmitter<IBubble>();

  @ViewChild(AtividadeComponent, { static: true }) viewAtividadeComponent;

  isFullSceen: boolean
  tabIndex: number;
  msgs: IMessage[] = [];
  idoso = true;
  ENTHEME = EnTheme;
  enActivityType: typeof EnActivityType = EnActivityType;
  wasDeletedAndIsEmpty = false;

  onEventBubble($event: IBubble): void {
    switch ($event.bubbleEvent) {
      case EnBubbleEvent.listDelete:
        this.eventBubble.emit($event);
        this.wasDeletedAndIsEmpty = true;
        break;
      case EnBubbleEvent.validateTabComponents:
        this.validateTabComponents($event.params.tabId, this.componentes, this.formGroup);
        break;
      case EnBubbleEvent.navigateNextBack:
        this.navigateNextBack($event, true, this.componentes, this.formGroup);
        break;
      default:
        this.eventBubble.emit($event);
        break;
    }
  }

  public get isDebug(): boolean {
    return this.cnfJson.isDebug || this.cnfJson.showInvisible;
  }

  public get isDialog(): boolean {
    return this.activityType === 'ListDetail';
  }

  public get showOnlySaveButton(): boolean {
    return this.cnfJson.showOnlySaveButton;
  }

  constructor(
    private log: LogService,
    private lib: LibService,
    protected msg: MsgPtBR,
    private glb: GlobalService,
    private cnfJson: ConfigJsonService,
  ) { super(); }

  ngOnInit() {
    try {
      document.addEventListener('fullscreenchange', () => this.onFullScreenChange());
      document.addEventListener('webkitfullscreenchange', () => this.onFullScreenChange());
      document.addEventListener('mozfullscreenchange', () => this.onFullScreenChange());
      document.addEventListener('MSFullscreenChange', () => this.onFullScreenChange());

      this.setTabsVisibility(this.componentes, this.tabs);

      const ambiente = this.cnfJson.replaceImgPath("");
      if (ambiente.includes("app")) {
        this.idoso = false;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /**
   * Chamará um método do componente filho no momento que todos os componentes estiverem de fato carregados.
   * No entanto, essa ciencia só existe na classe atividade componente view, que deverá portanto chamar esse método.
   */
  isFullScreen() {
    return document.fullscreenElement;
  }

  onFullScreenChange() {
    if (this.isFullScreen()) {
      this.isFullSceen = true
    } else {
      this.isFullSceen = false
    }
  }

  protected onAllComponentsLoaded(): void {
    try {
      if (this.viewAtividadeComponent) {
        this.viewAtividadeComponent.onAllComponentsLoaded();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAllComponentsLoaded', error.message);
    }
  }

  /**
   * Preenche a propriedade enabled de todas as abas, conforme a presença de pelo menos um controle visível.
   * Se for atividade standalone, as abas somente estarão visíveis conforme o progresso.
   */
  protected setTabsVisibility(componentes: any, tabs: IBasic[]): void {
    try {
      tabs.forEach(
        (f) =>
        (f.enabled =
          this.lib.isTabVisible(componentes, f) && this.isStandAloneAndVisible(f.id, this.activityType))
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setTabsVisibility', error.message);
    }
  }

  /**
   * No stand alone somente serão exibidas abas que já foram preenchidas, ou a atual.
   * Nesse cenário são exibidos botões de avançar e anterior e as abas vão sendo exibidas aos poucos.
   */
  protected isStandAloneAndVisible(tabId: number, activityType: EnActivityType): boolean {
    try {
      return (
        (tabId <= this.tabActivedId && activityType === EnActivityType.StandAlone) ||
        activityType !== EnActivityType.StandAlone
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isStandAloneAndVisible', error.message);
    }
    return false;
  }

  /* Evento chamado quando houver erro e preencherá a propriedade vinculada ao action-bar para exibição da mensagem de erro */
  errorNotify(msg: IMessage): void {
    try {
      if (this.lib.addIfNotExist(this.msgs, msg, 'detail')) {
        this.msgs.push(msg);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'errorNotify', error.message);
    }
  }

  /* Utilizado pelo AtividadeComponent para notificar uma mudança nos controles */
  changesNotify(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (this.formGroup.status !== 'PENDING' && this.formGroup.dirty) {
        const changed = this.componentes.map((m) => {
          if (+m.VariavelNo === +ctrl.VariavelNo) {
            m.IsVisible = ctrl.IsVisible;
          }

          return m;
        });

        this.setTabsVisibility(changed, this.tabs);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'changesNotify', error.message);
    }
  }

  /**
   * Verifica se a aba está ativa. Se for móvel, considerará sempre ativa, pois,
   * exibirá um abaixo do outro, Exceto se for StandAlone (conceito next).
   */
  isActived(tab: IBasic): boolean {
    try {
      if (this.isMobile && (this.activityType !== EnActivityType.StandAlone)) {
        return true;
      }
      if (this.tabs.length > this.tabActivedId) {
        return +this.tabActivedId === +tab.id;
      } else {
        return tab.id === 0;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isActived', error.message);
    }
    return false;
  }

  /**
   * Promove a navegação entre abas.
   * blockNextIfInvalid: se true, somente irá avançar passo caso todos os controles da aba estejam válidos.
   */
  protected navigateNextBack(
    $event: any,
    blockNextIfInvalid: boolean = false,
    components: IAtividadeComponenteDAL[],
    fg: FormGroup
  ): void {
    try {
      const step = $event.params.step;

      if (this.tabActivedId + step < 0 || this.tabActivedId + step >= this.tabs.length) {
        return;
      }

      if (step > 0) {
        if (blockNextIfInvalid) {
          const isValid = this.validateTabComponents(this.tabActivedId, components, fg);

          if (!isValid) {
            const message = {
              firstButtonLabel: this.msg.BUTTON_OK,
              title: this.msg.REQUIRED_FIELD_NOT_FILLED_TITLE,
              icon: 'fa-alert',
              text: this.msg.REQUIRED_FIELD_NOT_FILLED
            } as IAlertMessage;

            this.eventBubble.emit({
              $event,
              bubbleEvent: EnBubbleEvent.alertDialog,
              params: { message, hasConfirmButton: false }
            } as IBubble);
            return;
          }
        }
      }

      this.tabActivedId += step;
      this.tabs[this.tabActivedId].enabled = true;

      window.scrollTo(0, 0);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'navigateNextBack', error.message);
    }
  }

  /**
   * Dispara a validação dos componentes da aba fornecida como parâmetro.
   * Se tabId não for fornecido ou for = -1, validará todos os componentes.
   */
  validateTabComponents(
    tabId: number = -1,
    components: IAtividadeComponenteDAL[],
    fg: FormGroup
  ): boolean {
    try {
      tabId = !this.glb.IsNullOrEmpty(tabId) ? tabId : -1;
      const tabComponents = tabId < 0 ? components : components.filter((f) => f.TabIndex === tabId);

      return tabComponents.reduce((a, b) => {
        const ctrl = fg.get(this.lib.getId(b.VariavelNo));
        return a && !ctrl.invalid;
      }, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validateTabComponents', error.message);
    }
  }

  /* Calcula o zoom conforme a altura pré-definida e a altura da tela do usuário */
  getZoom(): number {
    try {
      if (this.isMobile) {
        return 1;
      } else {
        const alturaPadrao = 800; // descontados cabeçalho e rodapé e uma margem
        return window.outerHeight / alturaPadrao;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getZoom', error.message);
    }
  }

  getHeight() {
    return ((window.innerHeight - 300) / this.getZoom()) + 30 + 'px';
  }
}
