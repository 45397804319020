import { LibService } from '@medlogic/shared/geform';
import { Injectable } from '@angular/core';
import { PatientService } from './patient.service';
import {
  EnRequestType, IPatient, LogService,
  ConfigJsonService, GlobalService, routeGetPatientsWithMedications, EnContentType, routeGetAuthorizedPatients
} from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';

@Injectable({
  providedIn: 'root'
})
export class PatientCustomService extends PatientService {

  urlGetPatientsWithMedications = `${routeGetPatientsWithMedications}?cadPacienteNo={0}&cadEvolutionNo={1}&cadIntervecoesMedicamentosNo={2}&cadCheckMedicamentosNo={3}&cadIntervecoesHorariosNo={4}&cadVitalSignsNo={5}&tenantId={6}&dtStart={7}&dtEnd={8}&numOfDaysToCheckOcurrency={9}&cadAutorizacaoCuidadoNo={10}`;
  urlGetAuthorizedPatients = `${routeGetAuthorizedPatients}?cadPacienteNo={0}&cadAutorizacaoCuidadoNo={1}`;

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnfJson: ConfigJsonService,
    log: LogService,
    lib: LibService,
    protected basepage: BasePageService
  ) {
    super(cadastroSrv, glb, cnfJson, log, lib);
  }

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getByCodigoPaciente(ano: number, codigoPaciente: string, dtStart: Date = null, dtEnd: Date = null, lstVariables?: string): Observable<IPatient> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_2230:${codigoPaciente}`;

      return this.getFiltered(this.cadastroNo, filter, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoPaciente', error.message);
    }
    return of(null);
  }

  getPatientsWithMedications(
    cadPacienteNo: number,
    cadEvolutionNo: number,
    cadIntervecoesMedicamentosNo: number,
    cadCheckMedicamentosNo: number,
    cadIntervecoesHorariosNo: number,
    cadVitalSignsNo: number,
    cadAutorizacaoCuidadoNo: number,
    tenantId: number,
    dtStart: Date,
    dtEnd: Date,
    numOfDaysToCheckOcurrency: number): Observable<IPatient[]> {
    try {
      const urlGet = this.basepage.format(this.urlGetPatientsWithMedications,
        cadPacienteNo,
        cadEvolutionNo,
        cadIntervecoesMedicamentosNo,
        cadCheckMedicamentosNo,
        cadIntervecoesHorariosNo,
        cadVitalSignsNo,
        tenantId,
        this.glb.dateToYYYYMMdd(dtStart),
        this.glb.dateToYYYYMMdd(dtEnd),
        numOfDaysToCheckOcurrency,
        cadAutorizacaoCuidadoNo
      );
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getPatientsWithMedications', error.message);
    }
    return of(null);
  }

  getAuthorizedPatients(ano: number, cadAutorizacaoCuidadoNo: number): Observable<IPatient[]> {
    try {
      const urlGet = this.basepage.format(this.urlGetAuthorizedPatients, ano, cadAutorizacaoCuidadoNo);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {}, 2, EnContentType.Json);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAuthorizedPatients', error.message);
    }
    return of(null);
  }
}
