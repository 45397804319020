<div
  [formGroup]="formGroup"
  class="form-group"
  [class.has-error]="!isValid(formGroup, ctrl)"
  [libCtrConfigUI]="ctrl"
  [altura]="ctrl?.Altura"
  [class.black]="enTheme === ENTHEME.black"
>
  <lib-title-label
    [formGroup]="formGroup"
    [enTheme]="enTheme"
    [isMobile]="isMobile"
    [ctrl]="ctrl"
    #lblTitle
  >
  </lib-title-label>

  <ng-container *ngIf="!isMasked">
    <!--Exibido se o controle não possuir máscara-->
    <input
      title="input"
      matInput
      [type]="getInputType(ctrl, isMasked)"
      [id]="getId(ctrl)"
      [class.read-only]="!ctrl?.IsEnable"
      [style.height]="getStandardHeight()"
      [formControlName]="getId(ctrl)"
      [name]="getId(ctrl)"
      #input
      [style.width]="getWidth(ctrl)"
      [placeholder]="ctrl?.ToolTipMessage || ''"
      [class.black]="enTheme === ENTHEME.black"
      [tabIndex]="getTabIndex(ctrl)"
      [libCtrMobileConfigUI]="ctrl"
      [class.is-mobile]="isMobile"
      [required]="ctrl?.RequiredField"
    />
  </ng-container>

  <!--Exibido se o controle possuir máscara. O componente não aceita máscara vazia e não
    identifiquei outra forma de desligar o atributo condicionalmente -->
  <!-- O type precisa ser text pois o textMask usa a propriedade length. -->
  <ng-container *ngIf="isMasked">
    <ng-container
      *ngIf="getInputType(ctrl, isMasked) !== 'number'"
      [class.is-mobile]="isMobile"
    >
      <input
        title="input"
        matInput
        [style.width]="getWidth(ctrl)"
        [class.read-only]="!ctrl?.IsEnable"
        [style.height]="getStandardHeight()"
        [type]="getInputType(ctrl, isMasked)"
        [id]="getId(ctrl)"
        [formControlName]="getId(ctrl)"
        [name]="getId(ctrl)"
        #input
        [mask]="getMask(ctrl)"
        [prefix]="getPrefix(ctrl)"
        [suffix]="getSuffix(ctrl)"
        [dropSpecialCharacters]="dropChars(ctrl)"
        [placeholder]="ctrl?.ToolTipMessage || ''"
        [class.black]="enTheme === ENTHEME.black"
        [tabIndex]="getTabIndex(ctrl)"
        [libCtrMobileConfigUI]="ctrl"
        [class.is-mobile]="isMobile"
        [required]="ctrl?.RequiredField"
      />
    </ng-container>

    <!-- Tentativa de fazer que os números exibam o numeric key pad nativo. -->
    <ng-container
      *ngIf="getInputType(ctrl, isMasked) === 'number'"
      [class.is-mobile]="isMobile"
    >
      <input
        title="input"
        matInput
        [style.width]="getWidth(ctrl)"
        [class.read-only]="!ctrl?.IsEnable"
        [style.height]="getStandardHeight()"
        type="number"
        [id]="getId(ctrl)"
        [formControlName]="getId(ctrl)"
        [name]="getId(ctrl)"
        #input
        [placeholder]="ctrl?.ToolTipMessage || ''"
        [class.black]="enTheme === ENTHEME.black"
        [tabIndex]="getTabIndex(ctrl)"
        [libCtrMobileConfigUI]="ctrl"
        [class.is-mobile]="isMobile"
        [required]="ctrl?.RequiredField"
      />
    </ng-container>
  </ng-container>
</div>
