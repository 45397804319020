import { Pipe, PipeTransform } from '@angular/core';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'patientPipe'
})
export class PatientPipePipe implements PipeTransform {

  constructor(
    private glb: GlobalService,
    private log: LogService) { }

  transform(items: IPatient[], keyword: string): IPatient[] {
    try {
      if (this.glb.isNullOrEmpty(keyword)) { return items; }
      return items.filter(item => this.glb.contem(item.nomeHospede, keyword) || this.glb.contem(item.bairro, keyword));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return items;
  }


}
