import { addMedications, setIsLoadingMedication } from './../../state-medication/+state/medication.actions';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { GlobalService, IMedication, IPatient } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { catchError, first,  mergeMap, withLatestFrom, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IntervencoesMedicamentosService, PatientCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';
import { patientFail, loadPatients, loadPatientsSuccess, loadPatientsWithMedications } from './patient.actions';


@Injectable()
export class PatientEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private patientSrv: PatientCustomService, // TODO: ContaService changed to the API
    private intervMedSrv: IntervencoesMedicamentosService
  ) { }

  loadPatients$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadPatients),
      withLatestFrom(this.store),
      mergeMap(([_, state]) => {
        const { cadPacienteNo, cadAutorizacaoCuidadoNo } = state?.tenant?.selectedTenant;

        return this.patientSrv.getAuthorizedPatients(cadPacienteNo, cadAutorizacaoCuidadoNo)
          .pipe(
            first(),
            mergeMap(patients => {
              return this.intervMedSrv.getMedicationCountForPatients(state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo, patients)
                .pipe(
                  map(count => {
                    return patients.map(m => ({ ...m, calcMedicationsCount: count[+m.codigo] } as IPatient));
                  }));
            }),
            switchMap((patients: IPatient[]) => [
              patients ? loadPatientsSuccess({ patients }) : patientFail(null),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              return of(patientFail(null));
            })
          );
      })
    )
  );

  loadPatientWithMedications$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadPatientsWithMedications),
      withLatestFrom(this.store),
      mergeMap(([_, state]) => {
        this.store.dispatch(setIsLoading({ isLoading: true }));
        this.store.dispatch(setIsLoadingMedication({ isLoading: true }));

        const { tenantId, selectedTenant } = state?.tenant;
        const { cadPacienteNo,
          cadEvolutionNo,
          cadIntervecoesMedicamentosNo,
          cadCheckMedicamentosNo,
          cadIntervecoesHorariosNo,
          cadVitalSignsNo,
          cadAutorizacaoCuidadoNo } = selectedTenant;

        const dtEnd = this.glb.addDays(new Date(), 1); // Necessário para incluir a data do dia
        const dtStart = new Date();

        return this.patientSrv.getPatientsWithMedications(
          cadPacienteNo,
          cadEvolutionNo,
          cadIntervecoesMedicamentosNo,
          cadCheckMedicamentosNo,
          cadIntervecoesHorariosNo,
          cadVitalSignsNo,
          cadAutorizacaoCuidadoNo,
          tenantId,
          dtStart,
          dtEnd,
          1
        )
      }),
      switchMap((patients: IPatient[]) => [
        patients ? loadPatientsSuccess({ patients }) : patientFail(null),
        addMedications({ medications: this.getMedications(patients) }),
        setIsLoading({ isLoading: false }),
        setIsLoadingMedication({ isLoading: false })
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(patientFail(null));
      })
    ));

  /**
   * Além de extrair a lista de medicamentos de todos os pacientes, irá padronizar o prescribeTime,
   * pois o mesmo é utilizado para cálculos e também para a formação do id. Há registros que vem como 8 ao invés de 08:00
   */
  protected getMedications(patients: IPatient[]): IMedication[] {
    try {
      const mapMeds = (medications: IMedication[]) => {
        return medications?.filter(f => f)?.map(m => ({ ...m, prescribedTime: this.glb.formatTime(m.prescribedTime), status: (m.status || '') } as IMedication))
      };
      return patients?.reduce((a, b) => [...a, ...mapMeds(b.medications)], []);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedications', error.message);
    }
    return null;
  }

  protected firstUpperCase(str: string): string {
    try {
      const siglas = ['SIGLA'];
      if (siglas.includes(str.toUpperCase())) {
        return str.toUpperCase();
      }
      return this.glb.primeiraMaiuscula(str);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'firstUpperCase', error.message);
    }
    return str;
  }
}
