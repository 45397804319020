
import { refCount, publishReplay, map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IEvolucaoFisioterapia } from '@medlogic/shared/shared-interfaces';
import { Observable, EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class EvolucaoFisioterapiaService {

  protected lstVariaveis = 'V_387,V_391,V_1608,V_3328,V_3329,V_28051,V_101175,V_101305,V_101526,V_101527,V_101570,V_101573,V_101575,V_101578,V_101579,V_101580,V_101581,V_101690,V_101691,V_101692,V_104079,V_104082,V_104088,V_108537,V_108538,V_108539,V_108540,V_108541,V_108542,V_108543,V_108563,V_108564,V_108565,V_108567,V_108568,V_108569,V_108570,V_108571,V_108572,V_108573,V_108574,V_108575,V_108577,V_108578,V_108579,V_108583,V_108584,V_108594,V_108595,V_108596,V_108598,V_108600,V_108604,V_108605,V_108608,V_108609,V_108612,V_108613,V_108616,V_108617,V_108620,V_108621,V_108624,V_108625,V_108641,V_108642,V_108643,V_108644,V_108645,V_108646,V_108647,V_108648,V_108649,V_108650,V_108652,V_108653,V_108654,V_108655,V_108656,V_108657,V_108658,V_108659,V_108660,V_108661,V_108662,V_108663,V_108664,V_108665,V_108666,V_108667,V_108668,V_108669,V_108670,V_108671,V_108672,V_108673,V_108674,V_108675,V_108676,V_108677,V_108678,V_108679,V_108680,V_108681,V_108682,V_108683,V_108684,V_108685,V_108686,V_108687,V_108688,V_108689,V_108690,V_108691,V_108692,V_108693,V_108694,V_108695,V_108696,V_108697,V_108698,V_108699,V_108700,V_108701,V_108702,V_108703,V_108704,V_108705,V_108706,V_108707,V_108787,V_108788,V_108789,V_108790,V_108791,V_109181,V_109299,V_109321,V_109308,V_109309,V_109310,V_109300';

  recurrences: Array<IEvolucaoFisioterapia> = new Array<IEvolucaoFisioterapia>();

  cadastroNo = 24561;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IEvolucaoFisioterapia> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.dataEvolucao, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IEvolucaoFisioterapia> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IEvolucaoFisioterapia {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        nomePaciente: c.V_387,
        nascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_391),
        nomeMae: c.V_1608,
        identificacao1: c.V_3328,
        identificacao2: c.V_3329,
        prontuario: c.V_28051,
        dataAniversario: c.V_101175,
        dataEvolucao: this.glb.ddMMYYYYThhmmssToDate(c.V_101305),
        responsavelID: c.V_101526,
        responsavelNome: c.V_101527,
        admissao: c.V_101570,
        cPF: c.V_101573,
        idade: c.V_101575,
        endereco: c.V_101578,
        telefone: c.V_101579,
        celular: c.V_101580,
        email: c.V_101581,
        codigo: c.V_101690,
        titulo: c.V_101691,
        habilitado: this.glb.getBoolean(c.V_101692),
        nomeIdoso: c.V_104079,
        pai: c.V_104082,
        quarto: c.V_104088,
        funcaoMotoraMMSSDireito: c.V_108537,
        funcaoMotoraMMSSEsquerdo: c.V_108538,
        funcaoMotoraMMIIEsquerdo: c.V_108539,
        funcaoMotoraMMIIDireito: c.V_108540,
        avaliacaoMobilidadeNoLeitoRolarDireita: c.V_108541,
        avaliacaoMobilidadeNoLeitoRolarEsquerda: c.V_108542,
        pontetrocafralda: c.V_108543,
        timedUpAndGo: c.V_108563,
        usoCadeira: c.V_108564,
        testeSentaLevantaForcaMMII: c.V_108565,
        data: this.glb.ddMMYYYYThhmmssToDate(c.V_108567),
        titulacaoPressao: c.V_108568,
        fluxoOxigenio: c.V_108569,
        tipoaparelho: c.V_108570,
        equipamento: c.V_108571,
        seOutrosDescreva: c.V_108572,
        data2: this.glb.ddMMYYYYThhmmssToDate(c.V_108573),
        fluxoOxigenio2: c.V_108574,
        seOutrosDescreva2: c.V_108575,
        outros: c.V_108577,
        data3: this.glb.ddMMYYYYThhmmssToDate(c.V_108578),
        fisioterapeuta: c.V_108579,
        localatendimento: c.V_108583,
        seOutrosDescreva3: c.V_108584,
        bANDAGEMDataColocacao: this.glb.ddMMYYYYThhmmssToDate(c.V_108594),
        localAplicacao: c.V_108595,
        dataTroca: this.glb.ddMMYYYYThhmmssToDate(c.V_108596),
        dataEvolucao2: c.V_108598,
        numeroConselho: c.V_108600,
        todasAsDependenciasRIAE: c.V_108604,
        dispositivomarcha: c.V_108605,
        areasExternasRIAE: c.V_108608,
        dispositivomarcha2: c.V_108609,
        areasInternasRIAE: c.V_108612,
        dispositivomarcha3: c.V_108613,
        andarmoradia: c.V_108616,
        dispositivomarcha4: c.V_108617,
        foraandarmoradia: c.V_108620,
        dispositivomarcha5: c.V_108621,
        dentroquarto: c.V_108624,
        dispositivomarcha6: c.V_108625,
        data4: this.glb.ddMMYYYYThhmmssToDate(c.V_108641),
        origem: c.V_108642,
        numero: c.V_108643,
        todosOsMomentos: c.V_108644,
        auxilioDispositivo: c.V_108645,
        auxilioDispositivo2: c.V_108646,
        sairEntrarNoLeito: c.V_108647,
        cadeiraCadeira: c.V_108648,
        auxilioDispositivo3: c.V_108649,
        outros2: c.V_108650,
        periodoutilizacaoContinuo: this.glb.getBoolean(c.V_108652),
        periodoutilizacaoAlimentacao: this.glb.getBoolean(c.V_108653),
        periodoutilizacaoNoturno: this.glb.getBoolean(c.V_fv108654),
        periodoutilizacaoBanho: this.glb.getBoolean(c.V_108655),
        periodoutilizacaoDeambulacao: this.glb.getBoolean(c.V_108656),
        fisioterapiaMotora: this.glb.getBoolean(c.V_108657),
        fisioterapiaOrtopedica: this.glb.getBoolean(c.V_108658),
        procedimentoCirurgicoFratura: this.glb.getBoolean(c.V_108659),
        mSE: this.glb.getBoolean(c.V_108660),
        recomendacoes: c.V_108661,
        mSD: this.glb.getBoolean(c.V_108662),
        recomendacoes2: c.V_108663,
        respiratoria: this.glb.getBoolean(c.V_108664),
        analgesica: this.glb.getBoolean(c.V_108665),
        grupo: this.glb.getBoolean(c.V_108666),
        descreva: c.V_108667,
        outros3: c.V_108668,
        data5: this.glb.ddMMYYYYThhmmssToDate(c.V_108669),
        fisioterapeutaHomeCareResponsavel: c.V_108670,
        cREFITO: c.V_108671,
        fisioterapiaMotora2: this.glb.getBoolean(c.V_108672),
        fisioterapiaOrtopedica2: this.glb.getBoolean(c.V_108673),
        procedimentoCirurgicoFratura2: this.glb.getBoolean(c.V_108674),
        mSE3: this.glb.getBoolean(c.V_108675),
        recomendacoes3: c.V_108676,
        mIE: this.glb.getBoolean(c.V_108677),
        recomendacoes4: c.V_108678,
        respiratoria2: this.glb.getBoolean(c.V_108679),
        analgesica2: this.glb.getBoolean(c.V_108680),
        outros4: c.V_108681,
        localatendimento2: c.V_108682,
        seOutrosDescreva4: c.V_108683,
        tipocontrato: c.V_108684,
        telefone2: c.V_108685,
        mIE2: this.glb.getBoolean(c.V_108686),
        mID: this.glb.getBoolean(c.V_108687),
        mSD2: this.glb.getBoolean(c.V_108688),
        mID2: this.glb.getBoolean(c.V_108689),
        subirdescerEscadas: c.V_108690,
        tipo: c.V_108691,
        usobengala: c.V_108692,
        bIPAP: this.glb.getBoolean(c.V_108693),
        titulacao: c.V_108694,
        titulacao2: c.V_108695,
        bIPAP2: this.glb.getBoolean(c.V_108696),
        coluna: this.glb.getBoolean(c.V_108697),
        recomendacoes5: c.V_108698,
        costelas: this.glb.getBoolean(c.V_108699),
        recomendacoes6: c.V_108700,
        coluna2: this.glb.getBoolean(c.V_108701),
        recomendacoes7: c.V_108702,
        costelas2: this.glb.getBoolean(c.V_108703),
        recomendacoes8: c.V_108704,
        dt: c.V_108705,
        dt2: c.V_108706,
        dtroca: c.V_108707,
        profissionalOcorrencia: c.V_108787,
        dataCPAP: c.V_108788,
        dataO2: c.V_108789,
        dataFiosioRiae: c.V_108790,
        dataFisioExterno: c.V_108791,
        usoOxigenio: this.glb.getBoolean(c.V_109181),
        cpap: c.V_109299,
        campoAbertoLoc: c.V_109321,
        cadeiraHigienica: c.V_109308,
        auxilio: c.V_109309,
        campoAberto2: c.V_109310,
        trocas: c.V_109300,

      } as IEvolucaoFisioterapia;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /**
   * Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save(movimentacao: IEvolucaoFisioterapia, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(evolucaoFisioterapia: IEvolucaoFisioterapia): Array<IForm> {
    try {
      return [
        { VariavelNo: 387, ValorDado: evolucaoFisioterapia.nomePaciente || '' },
        { VariavelNo: 391, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.nascimento) },
        { VariavelNo: 1608, ValorDado: evolucaoFisioterapia.nomeMae || '' },
        { VariavelNo: 3328, ValorDado: evolucaoFisioterapia.identificacao1 || '' },
        { VariavelNo: 3329, ValorDado: evolucaoFisioterapia.identificacao2 || '' },
        { VariavelNo: 28051, ValorDado: evolucaoFisioterapia.prontuario || '' },
        { VariavelNo: 101175, ValorDado: evolucaoFisioterapia.dataAniversario || '' },
        { VariavelNo: 101305, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.dataEvolucao) },
        { VariavelNo: 101526, ValorDado: evolucaoFisioterapia.responsavelID || '' },
        { VariavelNo: 101527, ValorDado: evolucaoFisioterapia.responsavelNome || '' },
        { VariavelNo: 101570, ValorDado: evolucaoFisioterapia.admissao || '' },
        { VariavelNo: 101573, ValorDado: evolucaoFisioterapia.cPF || '' },
        { VariavelNo: 101575, ValorDado: evolucaoFisioterapia.idade || '' },
        { VariavelNo: 101578, ValorDado: evolucaoFisioterapia.endereco || '' },
        { VariavelNo: 101579, ValorDado: evolucaoFisioterapia.telefone || '' },
        { VariavelNo: 101580, ValorDado: evolucaoFisioterapia.celular || '' },
        { VariavelNo: 101581, ValorDado: evolucaoFisioterapia.email || '' },
        { VariavelNo: 101690, ValorDado: evolucaoFisioterapia.codigo || '' },
        { VariavelNo: 101691, ValorDado: evolucaoFisioterapia.titulo || '' },
        { VariavelNo: 101692, ValorDado: evolucaoFisioterapia.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 104079, ValorDado: evolucaoFisioterapia.nomeIdoso || '' },
        { VariavelNo: 104082, ValorDado: evolucaoFisioterapia.pai || '' },
        { VariavelNo: 104088, ValorDado: evolucaoFisioterapia.quarto || '' },
        { VariavelNo: 108537, ValorDado: evolucaoFisioterapia.funcaoMotoraMMSSDireito || '' },
        { VariavelNo: 108538, ValorDado: evolucaoFisioterapia.funcaoMotoraMMSSEsquerdo || '' },
        { VariavelNo: 108539, ValorDado: evolucaoFisioterapia.funcaoMotoraMMIIEsquerdo || '' },
        { VariavelNo: 108540, ValorDado: evolucaoFisioterapia.funcaoMotoraMMIIDireito || '' },
        { VariavelNo: 108541, ValorDado: evolucaoFisioterapia.avaliacaoMobilidadeNoLeitoRolarDireita || '' },
        { VariavelNo: 108542, ValorDado: evolucaoFisioterapia.avaliacaoMobilidadeNoLeitoRolarEsquerda || '' },
        { VariavelNo: 108543, ValorDado: evolucaoFisioterapia.pontetrocafralda || '' },
        { VariavelNo: 108563, ValorDado: evolucaoFisioterapia.timedUpAndGo || '' },
        { VariavelNo: 108564, ValorDado: evolucaoFisioterapia.usoCadeira || '' },
        { VariavelNo: 108565, ValorDado: evolucaoFisioterapia.testeSentaLevantaForcaMMII || '' },
        { VariavelNo: 108567, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.data) },
        { VariavelNo: 108568, ValorDado: evolucaoFisioterapia.titulacaoPressao || '' },
        { VariavelNo: 108569, ValorDado: evolucaoFisioterapia.fluxoOxigenio || '' },
        { VariavelNo: 108570, ValorDado: evolucaoFisioterapia.tipoaparelho || '' },
        { VariavelNo: 108571, ValorDado: evolucaoFisioterapia.equipamento || '' },
        { VariavelNo: 108572, ValorDado: evolucaoFisioterapia.seOutrosDescreva || '' },
        { VariavelNo: 108573, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.data) },
        { VariavelNo: 108574, ValorDado: evolucaoFisioterapia.fluxoOxigenio || '' },
        { VariavelNo: 108575, ValorDado: evolucaoFisioterapia.seOutrosDescreva || '' },
        { VariavelNo: 108577, ValorDado: evolucaoFisioterapia.outros || '' },
        { VariavelNo: 108578, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.data) },
        { VariavelNo: 108579, ValorDado: evolucaoFisioterapia.fisioterapeuta || '' },
        { VariavelNo: 108583, ValorDado: evolucaoFisioterapia.localatendimento || '' },
        { VariavelNo: 108584, ValorDado: evolucaoFisioterapia.seOutrosDescreva || '' },
        { VariavelNo: 108594, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.bANDAGEMDataColocacao) },
        { VariavelNo: 108595, ValorDado: evolucaoFisioterapia.localAplicacao || '' },
        { VariavelNo: 108596, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.dataTroca) },
        { VariavelNo: 108598, ValorDado: evolucaoFisioterapia.dataEvolucao || '' },
        { VariavelNo: 108600, ValorDado: evolucaoFisioterapia.numeroConselho || '' },
        { VariavelNo: 108604, ValorDado: evolucaoFisioterapia.todasAsDependenciasRIAE || '' },
        { VariavelNo: 108605, ValorDado: evolucaoFisioterapia.dispositivomarcha || '' },
        { VariavelNo: 108608, ValorDado: evolucaoFisioterapia.areasExternasRIAE || '' },
        { VariavelNo: 108609, ValorDado: evolucaoFisioterapia.dispositivomarcha || '' },
        { VariavelNo: 108612, ValorDado: evolucaoFisioterapia.areasInternasRIAE || '' },
        { VariavelNo: 108613, ValorDado: evolucaoFisioterapia.dispositivomarcha || '' },
        { VariavelNo: 108616, ValorDado: evolucaoFisioterapia.andarmoradia || '' },
        { VariavelNo: 108617, ValorDado: evolucaoFisioterapia.dispositivomarcha || '' },
        { VariavelNo: 108620, ValorDado: evolucaoFisioterapia.foraandarmoradia || '' },
        { VariavelNo: 108621, ValorDado: evolucaoFisioterapia.dispositivomarcha || '' },
        { VariavelNo: 108624, ValorDado: evolucaoFisioterapia.dentroquarto || '' },
        { VariavelNo: 108625, ValorDado: evolucaoFisioterapia.dispositivomarcha || '' },
        { VariavelNo: 108641, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.data) },
        { VariavelNo: 108642, ValorDado: evolucaoFisioterapia.origem || '' },
        { VariavelNo: 108643, ValorDado: evolucaoFisioterapia.numero || '' },
        { VariavelNo: 108644, ValorDado: evolucaoFisioterapia.todosOsMomentos || '' },
        { VariavelNo: 108645, ValorDado: evolucaoFisioterapia.auxilioDispositivo || '' },
        { VariavelNo: 108646, ValorDado: evolucaoFisioterapia.auxilioDispositivo || '' },
        { VariavelNo: 108647, ValorDado: evolucaoFisioterapia.sairEntrarNoLeito || '' },
        { VariavelNo: 108648, ValorDado: evolucaoFisioterapia.cadeiraCadeira || '' },
        { VariavelNo: 108649, ValorDado: evolucaoFisioterapia.auxilioDispositivo || '' },
        { VariavelNo: 108650, ValorDado: evolucaoFisioterapia.outros || '' },
        { VariavelNo: 108652, ValorDado: evolucaoFisioterapia.periodoutilizacaoContinuo ? 'SIM' : 'NÃO' },
        { VariavelNo: 108653, ValorDado: evolucaoFisioterapia.periodoutilizacaoAlimentacao ? 'SIM' : 'NÃO' },
        { VariavelNo: 108654, ValorDado: evolucaoFisioterapia.periodoutilizacaoNoturno ? 'SIM' : 'NÃO' },
        { VariavelNo: 108655, ValorDado: evolucaoFisioterapia.periodoutilizacaoBanho ? 'SIM' : 'NÃO' },
        { VariavelNo: 108656, ValorDado: evolucaoFisioterapia.periodoutilizacaoDeambulacao ? 'SIM' : 'NÃO' },
        { VariavelNo: 108657, ValorDado: evolucaoFisioterapia.fisioterapiaMotora ? 'SIM' : 'NÃO' },
        { VariavelNo: 108658, ValorDado: evolucaoFisioterapia.fisioterapiaOrtopedica ? 'SIM' : 'NÃO' },
        { VariavelNo: 108659, ValorDado: evolucaoFisioterapia.procedimentoCirurgicoFratura ? 'SIM' : 'NÃO' },
        { VariavelNo: 108660, ValorDado: evolucaoFisioterapia.mSE ? 'SIM' : 'NÃO' },
        { VariavelNo: 108661, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108662, ValorDado: evolucaoFisioterapia.mSD ? 'SIM' : 'NÃO' },
        { VariavelNo: 108663, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108664, ValorDado: evolucaoFisioterapia.respiratoria ? 'SIM' : 'NÃO' },
        { VariavelNo: 108665, ValorDado: evolucaoFisioterapia.analgesica ? 'SIM' : 'NÃO' },
        { VariavelNo: 108666, ValorDado: evolucaoFisioterapia.grupo ? 'SIM' : 'NÃO' },
        { VariavelNo: 108667, ValorDado: evolucaoFisioterapia.descreva || '' },
        { VariavelNo: 108668, ValorDado: evolucaoFisioterapia.outros || '' },
        { VariavelNo: 108669, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFisioterapia.data) },
        { VariavelNo: 108670, ValorDado: evolucaoFisioterapia.fisioterapeutaHomeCareResponsavel || '' },
        { VariavelNo: 108671, ValorDado: evolucaoFisioterapia.cREFITO || '' },
        { VariavelNo: 108672, ValorDado: evolucaoFisioterapia.fisioterapiaMotora ? 'SIM' : 'NÃO' },
        { VariavelNo: 108673, ValorDado: evolucaoFisioterapia.fisioterapiaOrtopedica ? 'SIM' : 'NÃO' },
        { VariavelNo: 108674, ValorDado: evolucaoFisioterapia.procedimentoCirurgicoFratura ? 'SIM' : 'NÃO' },
        { VariavelNo: 108675, ValorDado: evolucaoFisioterapia.mSE ? 'SIM' : 'NÃO' },
        { VariavelNo: 108676, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108677, ValorDado: evolucaoFisioterapia.mIE ? 'SIM' : 'NÃO' },
        { VariavelNo: 108678, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108679, ValorDado: evolucaoFisioterapia.respiratoria ? 'SIM' : 'NÃO' },
        { VariavelNo: 108680, ValorDado: evolucaoFisioterapia.analgesica ? 'SIM' : 'NÃO' },
        { VariavelNo: 108681, ValorDado: evolucaoFisioterapia.outros || '' },
        { VariavelNo: 108682, ValorDado: evolucaoFisioterapia.localatendimento || '' },
        { VariavelNo: 108683, ValorDado: evolucaoFisioterapia.seOutrosDescreva || '' },
        { VariavelNo: 108684, ValorDado: evolucaoFisioterapia.tipocontrato || '' },
        { VariavelNo: 108685, ValorDado: evolucaoFisioterapia.telefone || '' },
        { VariavelNo: 108686, ValorDado: evolucaoFisioterapia.mIE ? 'SIM' : 'NÃO' },
        { VariavelNo: 108687, ValorDado: evolucaoFisioterapia.mID ? 'SIM' : 'NÃO' },
        { VariavelNo: 108688, ValorDado: evolucaoFisioterapia.mSD ? 'SIM' : 'NÃO' },
        { VariavelNo: 108689, ValorDado: evolucaoFisioterapia.mID ? 'SIM' : 'NÃO' },
        { VariavelNo: 108690, ValorDado: evolucaoFisioterapia.subirdescerEscadas || '' },
        { VariavelNo: 108691, ValorDado: evolucaoFisioterapia.tipo || '' },
        { VariavelNo: 108692, ValorDado: evolucaoFisioterapia.usobengala || '' },
        { VariavelNo: 108693, ValorDado: evolucaoFisioterapia.bIPAP ? 'SIM' : 'NÃO' },
        { VariavelNo: 108694, ValorDado: evolucaoFisioterapia.titulacao || '' },
        { VariavelNo: 108695, ValorDado: evolucaoFisioterapia.titulacao || '' },
        { VariavelNo: 108696, ValorDado: evolucaoFisioterapia.bIPAP ? 'SIM' : 'NÃO' },
        { VariavelNo: 108697, ValorDado: evolucaoFisioterapia.coluna ? 'SIM' : 'NÃO' },
        { VariavelNo: 108698, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108699, ValorDado: evolucaoFisioterapia.costelas ? 'SIM' : 'NÃO' },
        { VariavelNo: 108700, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108701, ValorDado: evolucaoFisioterapia.coluna ? 'SIM' : 'NÃO' },
        { VariavelNo: 108702, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108703, ValorDado: evolucaoFisioterapia.costelas ? 'SIM' : 'NÃO' },
        { VariavelNo: 108704, ValorDado: evolucaoFisioterapia.recomendacoes || '' },
        { VariavelNo: 108705, ValorDado: evolucaoFisioterapia.dt || '' },
        { VariavelNo: 108706, ValorDado: evolucaoFisioterapia.dt || '' },
        { VariavelNo: 108707, ValorDado: evolucaoFisioterapia.dtroca || '' },
        { VariavelNo: 108787, ValorDado: evolucaoFisioterapia.profissionalOcorrencia || '' },
        { VariavelNo: 108788, ValorDado: evolucaoFisioterapia.dataCPAP || '' },
        { VariavelNo: 108789, ValorDado: evolucaoFisioterapia.dataO2 || '' },
        { VariavelNo: 108790, ValorDado: evolucaoFisioterapia.dataFiosioRiae || '' },
        { VariavelNo: 108791, ValorDado: evolucaoFisioterapia.dataFisioExterno || '' },
        { VariavelNo: 109181, ValorDado: evolucaoFisioterapia.usoOxigenio ? 'SIM' : 'NÃO' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Retorna um título bem formatado para cada campo.  */
  getRotulo(field: string): string {
    try {
      const mapR = {
        codigoPaciente: '#NAOEXIBIR#',
        dataavaliacao: '#NAOEXIBIR#',
        codigo: '#NAOEXIBIR#',
        titulo: '#NAOEXIBIR#',
        habilitado: '#NAOEXIBIR#',
        laudo: 'Laudo',
        oCORRENCIA: '#NAOEXIBIR#',
        codPacCAT: '#NAOEXIBIR#',
        codPacOcorrenciaCAT: '#NAOEXIBIR#',
        executorAvaliacao: '#NAOEXIBIR#',
        executorMedicaoData: '#NAOEXIBIR#',
        codPacPressao: '#NAOEXIBIR#',
        cODHOSPDATA: '#NAOEXIBIR#',
        tecnicoResponsavel: 'Profissional',
        frequenciaCardiacaBpm: 'Frequência Cardíaca (BPM)',
        frequenciaRespiratoriaICP: 'Frequência Respiratória (ICP)',
        glicemiaCapilarEmJejumMlDl: '(Glicemia Capilar em Jejum (ml/dl)',
        posPrandialMlDl: 'Glicemia pós-prandial (ml/dl)',
        glicose: 'Glicose',
        pADiastolicaRef80: 'Pressão Diastólica (ref: 80)',
        pASistolicaRef120: 'Pressão Sistólica (ref: 120)',
        saturacaoOxigenioSO: 'Saturação de Oxigênio',
        temperaturaTax: 'Temperatura'
      };
      if (mapR.hasOwnProperty(field)) {
        return mapR[field];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRotulo', error.message);
    }
    return '';
  }
}
