import { ConfigStateService } from '@medlogic/shared/state-config';
import { FormGroup } from '@angular/forms';
import { InputMaskType } from '../../enum/input-mask-type';
import { CalculadoraService } from '../../service/calculadora.service';
import { AutoCalcControl } from '../../model/auto-calc-control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';
// Referência sobre o componente de máscara
// https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#pipe

@Component({
  selector: 'lib-ctr-textbox-label',
  templateUrl: './ctr-textbox-label.component.html',
  styleUrls: ['./ctr-textbox-label.component.scss']
})
export class CtrTextBoxLabelComponent extends AutoCalcControl implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  @Output() eventBubble = new EventEmitter<IBubble>();

  private _isMasked = false;
  public get isMasked(): boolean {
    return this._isMasked;
  }
  public set isMasked(v: boolean) {
    this._isMasked = v;
  }

  ENTHEME = EnTheme;

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR
  ) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  ngOnInit(): void {
    try {
      this.setIsMasked(this.ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  protected applyDefaultMasksRules(): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyDefaultMasksRules', error.message);
    }
  }

  /*Verifica se há uma configuração de máscara para o controle no GE. */
  protected setIsMasked(ctrl: IAtividadeComponenteDAL): void {
    try {
      this.isMasked = this.getMask(ctrl) !== null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isMasked', error.message);
    }
  }

  getMask(ctrl: IAtividadeComponenteDAL): string {
    try {
      return InputMaskType.getNgxMask(ctrl?.InputMaskType, 2, ctrl?.MaxLength);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMask', error.message);
    }
    return '';
  }

  /* Os campos de data deverão manter os caracteres. */
  dropChars(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return ![
        // todos os tipos que puderem ser iniciado por zero precisam manter os chars.
        InputMaskType.Date,
        InputMaskType.DateTime,
        InputMaskType.CPF,
        InputMaskType.CNPJ,
        InputMaskType.TimeHHHMM,
        InputMaskType.TimeHHMM,
        InputMaskType.TimeHHMMSS,
        InputMaskType.CEP,
        InputMaskType.Password,
        InputMaskType.PhoneWithDDD,
        InputMaskType.PhoneWithDDDInternational,
        InputMaskType.PhoneWithDDDNoPrefix,
        InputMaskType.PhoneWithDDDNoPrefixInternational,
      ].includes(InputMaskType.toEnum(ctrl?.InputMaskType));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dropChars', error.message);
    }
    return false;
  }

  /*Retorna o tipo de input conforme a máscara configurada no controle. */
  getInputType(ctrl: IAtividadeComponenteDAL, isMasked: boolean = false): string {
    try {
      if (isMasked) {
        return 'text';
      }
      switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
        case InputMaskType.DateTime:
          return 'datetime-local';
        case InputMaskType.Date:
          return 'date';
        case InputMaskType.TimeHHHMM:
        case InputMaskType.TimeHHMM:
        case InputMaskType.TimeHHMMSS:
          return 'time';
        case InputMaskType.Email:
          return 'email';
        // Todos receberão dados em inglês e precisam ser convertidos para português
        case InputMaskType.Integer:
        case InputMaskType.Percent:
        case InputMaskType.Percent2:
        case InputMaskType.Real:
        case InputMaskType.Numeric:
        case InputMaskType.Numeric2:
        case InputMaskType.Numeric3:
        case InputMaskType.Numeric4:
          return 'number';
        case InputMaskType.Phone:
        case InputMaskType.PhoneWithDDD:
        case InputMaskType.PhoneWithDDDInternational:
        case InputMaskType.PhoneWithDDDNoPrefix:
        case InputMaskType.PhoneWithDDDNoPrefixInternational:
          return 'tel';
        case InputMaskType.Password:
          return 'password';
        default:
          return 'text';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getInputType', error.message);
    }
    return 'text';
  }

  getPrefix(ctrl: IAtividadeComponenteDAL): string {
    try {
      switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
        case InputMaskType.Real:
          return 'R$';
        case InputMaskType.Dolar:
          return '$';
        default:
          return '';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getPrefix', error.message);
    }
    return '';
  }

  getSuffix(ctrl: IAtividadeComponenteDAL): string {
    try {
      switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
        case InputMaskType.Percent:
        case InputMaskType.Percent2:
          return '%';
        default:
          return '';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSuffix', error.message);
    }
    return '';
  }


}
