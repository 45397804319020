import { MedlogicTenantService } from '@medlogic/medlogic/medlogic-data-access';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { errorTenant, goRoot, setTenant, successTenant, TenantActionTypes } from './medlogic-tenant.actions';
import { ITenantState } from '@medlogic/shared/shared-interfaces';
import { LocalNavigationService } from 'libs/medlogic-pwa/medlogic-pwa-cuidado-full/src/lib/module/core/service/local-navigation.service';

import * as fromLogin from '../../state-login/+state/login.actions';

@Injectable()
export class TenantEffects {

  loadTenant$ = createEffect(() => this.actions$
    .pipe(
      ofType(TenantActionTypes.LoadTenant),
      mergeMap((state: ITenantState) => {
        return this.tenantService.get(state, false)
          .pipe(
            map(selectedTenant => {
              return setTenant({ selectedTenant });
            }),
            catchError((e) => {
              console.log(e);
              return of(errorTenant());
            })
          );
      })
    )
  );

  loadTenantGoRoot$ = createEffect(() => this.actions$
    .pipe(
      ofType(TenantActionTypes.LoadTenantGoRoot),
      mergeMap((state: ITenantState) => {
        return this.tenantService.get(state, false)
          .pipe(
            switchMap((selectedTenant) => {
              if (!!selectedTenant) {
                return [setTenant({ selectedTenant }), goRoot()];
              }
              return [errorTenant(), fromLogin.logOut()];
            }),
            catchError((e) => {
              console.log(e);
              return of(errorTenant());
            })
          );
      })
    )
  );

  goRoot$ = createEffect(() => this.actions$
    .pipe(
      ofType(TenantActionTypes.GoRoot),
      mergeMap((state: ITenantState) => {
        this.nav2.gotoRoot();
        return of(successTenant());
      }),
      catchError((e) => {
        console.log(e);
        return of(errorTenant());
      })
    )
  );

  constructor(
    private actions$: Actions,
    private tenantService: MedlogicTenantService,
    private nav2: LocalNavigationService
  ) { }

}
