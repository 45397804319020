<div class="grid" [class.dark-mode]="enTheme === ENTHEME.black">

  <div class="circle" appRandomBgColor="listItem?.color">{{ getLetter(listItem?.topLeft) }}</div>
  <h1 class="topLeft">{{ listItem?.topLeft }}</h1>
  <h2 class="bottomLeft">{{ listItem?.bottomLeft }}</h2>
  <span class="topRight">{{ listItem?.topRight }}</span>
  <span class="bottomRight">{{ listItem?.bottomRight }} - [{{ listItem?.ono }}]</span>
  <mat-icon class="arrow">keyboard_arrow_right</mat-icon>

</div>
