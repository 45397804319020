import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { BasePageService } from './base-page.service';
import {
  EnRequestType, EnContentType,
  LogService, IGetFormByNameResponse,
  routeGetFormByName, ConfigJsonService, error
} from '@medlogic/shared/shared-interfaces';

@Injectable({
  providedIn: 'root'
})
export class CadTenantService {
  urlGet = `${routeGetFormByName}?cadastroNome={0}`;
  cadTenantName = this.cnfJson.cadConfigName || 'CAD - CONFIG IDOSO BEM CUIDADO';
  dtInicial: any;
  dtFinal: any;

  constructor(
    private cnfJson: ConfigJsonService,
    private basepage: BasePageService,
    private log: LogService
  ) { }

  /**
    * Retorna o número do cadastro escolhido pelo nome
    * antigo getCadTenantConfig.
    */
  getCadTenantConfig(): Observable<IGetFormByNameResponse> {
    try {
      const urlGet = this.basepage.format(this.urlGet, this.cadTenantName);
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {}, 3, EnContentType.Json)
        .pipe(
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadTenantConfig', error.message);
    }
    return of(null);
  }
}
