import { Injectable } from '@angular/core';
import { EnActivityType, IActivity, IProgress, routeProgress } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirProgressService extends FhirGenericService {

  protected url = routeProgress;

  getActivityByType(activityType: EnActivityType): Observable<IActivity | null> {
    try {
      const url = `${this.getUrl()}getActivityByType?type=${activityType}`;
      return this.http.get<IActivity>(url);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getActivityByType', error.message);
    }
    return of(null);
  }

  createUnscheduledProgress<T>(data: IProgress | null, medlogicId: number | null): Observable<T | null> {
    try {
      const url = `${this.getUrl()}postUnscheduledProgress?medlogicId=${medlogicId}`;
      return this.http.post<T>(url, { data });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'createUnscheduledProgress', error.message);
    }
    return of(null);
  }

}
