<div>
  <div class="top-bar">
    <button mat-button type="button" (click)="onBack($event)" class="btn-back">
      <mat-icon class="icn-back" style="font-size: 15px; margin-top: 5px;">close</mat-icon>
      <span>CANCELAR</span>
    </button>
  </div>

  <div class="tab-content" style="min-height: 580px">
    <div class="tab-pane fade in active" id="webCam">
      <div class="row">
        <div class="col-md-6" [class.hidden]="context">
          <div class="box">
            <div class="box-header with-border"></div>

            <div class="box-body">
              <video
                #video
                id="video_ctrImage"
                width="640"
                height="480"
                autoplay
              ></video>

              <button mat-raised-button (click)="capture($event)" class="capturar btn-primary">
                <mat-icon>donut_large</mat-icon>CAPTURAR
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-6" [class.hidden]="!context">
          <div class="box">
            <div class="box-body">
              <div style="width: 640px; height: 480px">
                <canvas
                  #canvas
                  id="canvas_ctrImage"
                  width="640"
                  height="480"
                ></canvas>
              </div>

              <br />

              <div class="col-md-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
