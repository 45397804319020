import { ConfigStateService } from '@medlogic/shared/state-config';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { NavigationService } from '../../service/navigation.service';
import { CadastroListaDAL } from '../../model/dal/cadastro-lista-dal';
import { CalculadoraService } from '../../service/calculadora.service';
import { ICampoAdicional } from '../../interface/icampo-adicional';
import { ListControl } from '../../model/list-control';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { ExecucaoTarefaDAL } from '../../model/dal/execucao-tarefa-dal';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ICadastroListaDAL } from '../../interface/icadastro-lista-dal';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { EnTypedValue } from '@medlogic/shared/shared-interfaces';
import { ITypedValue } from '@medlogic/shared/shared-interfaces';
import { StoreProviderService } from '@medlogic/shared/utils';
import { EnMaterialIcon } from '@medlogic/shared/gecore';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GeFormProviderService } from '../../service/ge-form-provider.service';
import { FormGroup } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';

@Component({
  selector: 'lib-ctr-grid',
  templateUrl: './ctr-grid.component.html',
  styleUrls: ['./ctr-grid.component.css']
})
export class CtrGridComponent extends ListControl implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  @Output() eventBubble = new EventEmitter<IBubble>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<ICadastroListaDAL> = null;
  ENTHEME = EnTheme;
  columns: ICampoAdicional[];
  search = '';
  currentValorTexto: string;
  textBtnNew = this.msg.BTN_NEW;
  textBtnDelete = this.msg.BUTTON_DELETE;
  displayedColumns = [];
  selection = new SelectionModel<any>(true, []);

  public get lstCadastroAdicional(): any[] {
    return this.ctrl?.lstCadastroAdicional;
  }

  constructor(
    global: GlobalService,
    log: LogService,
    lib: LibService,
    cnf: ConfigStateService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    cadastroListaDAL: CadastroListaDAL,
    config: ConfigJsonService,
    router: Router,
    navigation: NavigationService,
    execucaoTarefa: ExecucaoTarefaDAL,
    dialog: MatDialog,
    msg: MsgPtBR,
    geFormPrv: GeFormProviderService,
    protected sp: StoreProviderService,
    private ref: ChangeDetectorRef
  ) {
    super(
      global,
      log,
      lib,
      cnf,
      calc,
      calcCond,
      dialog,
      msg,
      cadastroListaDAL,
      config,
      router,
      navigation,
      execucaoTarefa,
      geFormPrv
    );
  }

  /* Override */
  ngOnInit() {
    try {
      super.ngOnInit();
      if (this.dataSource) {
        this.dataSource.sort = this.sort;
      }

      this.subs.sink = this.formGroup
        .valueChanges
        .subscribe(s => {
          const gridValue = s[this.lib.getId(this.ctrl?.VariavelNo)];
          if (gridValue && gridValue !== this.currentValorTexto) {
            this.refresh();
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Override */
  protected refresh(): void {
    try {
      this.columns = this.getColumns(this.ctrl);
      this.displayedColumns = ['select', ...this.columns.map(m => m.ColumnName)];
      this.currentValorTexto = this.ctrl?.ValorTexto;

      const ano = this.cnf.ModeloAtividadeNo;
      this.subs.sink = this.getValorTextoOrLoadCadastroAdicional(ano, this.ctrl)
        .subscribe(
          (items) => {
            try {
              this.updateValorTextoAndLstCadastroAdicional(items, this.ctrl);
              this.dataSource = new MatTableDataSource<ICadastroListaDAL>(items);
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'refresh.loaded', error.message);
            }
            this.isLoading = false;
          }
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /**
   * Se houver ValorTexto, desempacota no formato do grid.
   * Em seguida, checa se config.gridItems tem item a ser carregado, que é preenchido no caso de edição de um grid, na qual o item editado é preenchido em gridItems.
   * Caso contrário, faz validação para ver se é associado a cadastro e carrega do serviço, pois, senão, deve ficar em branco.
   */
  protected getValorTextoOrLoadCadastroAdicional(
    ano: number,
    ctrl: IAtividadeComponenteDAL
  ): Observable<ICadastroListaDAL[]> {
    try {
      let obs: Observable<any> = of(null);
      const isCascade = this.lib.isCascade(ctrl);

      if (!this.global.IsNullOrEmpty(ctrl.ValorTexto)) {
        obs = this.lib.transformValorTextoInLstCadastroAdicional(ctrl);
      } else if (ctrl.CanAddInCadastro) {
        obs = this.getCadastroAdicional(
          +ctrl.CadastroNo,
          +this.cnf.usuarioLogadoNo,
          +ctrl.VariavelRetornoNo,
          ctrl.Type,
          +ctrl.AtividadeComponenteNo,
          ctrl,
          this.valorFiltro,
          isCascade
        );
      }

      return obs;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValorTextoOrLoadCadastroAdicional', error.message);
    }
    return of(null);
  }

  protected getColumns(ctrl: IAtividadeComponenteDAL): ICampoAdicional[] {
    try {
      return ctrl.LstCamposAdicionais.CamposAdicionais.filter((f) => {
        return f.IsVisible && f.Largura > 0;
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getColumns', error.message);
    }
  }

  getValue(col: ICampoAdicional, item: IAtividadeComponenteDAL): any {
    try {
      if (item && item[this.getClmName(col)]) {
        const value = item[this.getClmName(col)];
        let typed: ITypedValue;
        if (value && value.name) {
          typed = this.global.getTypedValue(value.name, 'en-US', 'pt-BR', 2);
        } else {
          typed = this.global.getTypedValue(value, 'en-US', 'pt-BR', 2);
        }
        switch (typed.type) {
          case EnTypedValue.Date:
            return this.global.FormatarData(typed.value);

          case EnTypedValue.Dollar:
          case EnTypedValue.Float:
          case EnTypedValue.Real:
          case EnTypedValue.Percent:
            return typed.string;

          case EnTypedValue.Integer:
            return typed.value;

          default:
            return typed.string;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValue', error.message);
    }
    return null;
  }

  protected onCheckAll(items: any[], value: any): void {
    try {
      items.forEach((item) => (item.isSelected = value.checked));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onChecked', error.message);
    }
  }

  /* Quando um item é selecionado/desselecionado. */
  protected onCheck(item: any, checkbox: any): void {
    try {
      if (item.isSelected === undefined) {
        item.isSelected = true;
      } else {
        item.isSelected = checkbox.checked;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onCheck', error.message);
    }
  }

  hasItems(): boolean {
    try {
      return this.lstCadastroAdicional ? this.lstCadastroAdicional.length > 0 : false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasItems', error.message);
    }
  }

  protected getClmName(col: ICampoAdicional): string {
    try {
      return `V_${col.VariavelNo}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getClmName', error.message);
    }
  }

  onSearchChange(keyword: string): void {
    try {
      this.search = keyword;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSearchChange', error.message);
    }
  }

  onSearchEsc(event: any): void {
    try {
      this.search = '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSearchEsc', error.message);
    }
  }

  /*Override  */
  isReadOnlyGrid(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return super.isReadOnly(ctrl) || !ctrl.CanEditItem;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isReadOnlyGrid', error.message);
    }
    return false;
  }

  /**
   * Edita o item.
   * Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
   */
  onEditItem(
    $event: any,
    anoMain: number,
    anoChild: number,
    selectedItem: any,
    saveInList: boolean,
    gridVariavelNo: number,
    isReadOnly: boolean,
    readOnly: boolean
  ): void {
    try {
      if (isReadOnly) {
        return;
      }

      this.cnf.setDefaultFormControls(
        anoChild,
        this.lib.getDefaultFromGridAndActivity(selectedItem, this.formGroup)
      );
      selectedItem.gridVariavelNo = gridVariavelNo;

      const ono = selectedItem.OcorrenciaNo || selectedItem.index || selectedItem.ocorrenciaNo;
      if (!this.global.isNullOrEmpty(ono)) {
        this.subs.sink = this.onEdit(
          $event,
          anoMain,
          anoChild,
          ono,
          gridVariavelNo,
          readOnly,
          saveInList).subscribe(res => this.updateGridUI(this.ctrl, res?.values));
      } else {
        this.subs.sink = this.openDialog(
          this.msg.CTR_GRID_EDIT_INVALID_INDEX_TITLE,
          this.msg.CTR_GRID_EDIT_INVALID_INDEX,
          'OK',
          '40vw',
          EnMaterialIcon.error_outline
        ).subscribe();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onEditItem', error.message);
    }
  }

  /*Acrescenta um item no grid. */
  onNewItem($event: any, anoMain: number, anoChild: number, saveInList: boolean, gridVariavelNo: number): void {
    try {
      const newItem = {
        index: -1,
        label: '',
        gridVariavelNo
      };
      this.cnf.setDefaultFormControls(anoChild, this.lib.getDefaultFromGridAndActivity(newItem, this.formGroup));
      this.subs.sink = this.onNew(
        $event,
        anoMain,
        anoChild,
        gridVariavelNo,
        saveInList)
        .subscribe(res => {
          this.updateGridUI(this.ctrl, res?.values);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onNewItem', error.message);
    }
  }

  private updateGridUI(ctrl: IAtividadeComponenteDAL, values: { [key: string]: any }): void {
    try {
      this.dataSource.filter = this.search;
      this.ref.detectChanges();
      this.updateCadastroDependends(ctrl, values);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateGridUI', error.message);
    }
  }

  /**
   * Abre uma janela de confirmação com a quantidade de itens. Se confirmado, exclui os itens do Grid e do BD.
   */
  onDeleteItems($event: any, addInCadastro: boolean) {
    try {
      const selectedItems = this.selection.selected;
      const count = selectedItems.length;

      if (count > 0) {
        const esse = count > 1 ? 'esses' : 'esse';
        const item = count > 1 ? 'items' : 'item';

        this.hasConfirmButton = true;

        this.message = {
          firstButtonLabel: 'Não',
          title: 'Confirmação',
          icon: 'fa-times',
          text: `Você tem certeza que quer excluir ${esse} ${count} ${item}`,
          acceptFunc: (result: boolean) => {
            try {
              if (result) {
                this.ctrl.lstCadastroAdicional = this.removeDeleteItems(
                  this.lstCadastroAdicional,
                  selectedItems,
                  'index',
                  addInCadastro,
                  this.ctrl?.AtividadeCadastroNo
                );

                this.updateValorTextoAndLstCadastroAdicional(this.lstCadastroAdicional, this.ctrl, null, true);
                this.dataSource = new MatTableDataSource<ICadastroListaDAL>(this.lstCadastroAdicional);
                this.updateGridUI(this.ctrl, null);
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'acceptFunc', error.message);
            }
          }
        };
      } else {
        this.hasConfirmButton = false;

        this.message = {
          firstButtonLabel: 'OK',
          title: 'Atenção',
          icon: 'fa-times',
          text: `Nenhum item selecionado!`,
        };
      }
      this.onAlertDialog($event, this.message, this.hasConfirmButton);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDelete', error.message);
    }
  }

  /* OVERRIDE */
  protected removeDeleteItems(
    items: any[],
    selectedItems: any[],
    campoOcorrenciaNo: string,
    removeFromCadastro: boolean,
    atividadeCadastroNo: number
  ): any[] {
    try {
      const objGrid = this.lstCadastroAdicional;
      if (objGrid) {
        selectedItems?.forEach((f) => {
          try {
            const item = objGrid.find(
              (g) => g.gridVariavelNo === this.ctrl?.VariavelNo && g.index === f.index
            );
            if (item) {
              const idx = objGrid.indexOf(item);
              objGrid.splice(idx, 1);
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'deleteItems', error.message);
          }
        });
      }

      return super.removeDeleteItems(
        items,
        selectedItems,
        campoOcorrenciaNo,
        removeFromCadastro,
        atividadeCadastroNo
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteItems', error.message);
    }
  }

  /**
   * Retornar o número de colunas do grid, mas considerando que haverá uma coluna a mais para o checkbox.
   */
  getColumnsLength(): number {
    try {
      const inc = this.hasItems() ? 1 : 0;
      return this.columns.length + inc;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getColumnsLength', error.message);
    }
    return 0;
  }

  /**
   * Whether the number of selected elements matches the total number of rows.
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource && this.dataSource.data ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else if (this.dataSource && this.dataSource.data && this.selection) {
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  /**
   * The label for the checkbox on the passed row.
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /**
   * Calculate the displayed columns according screen size.
   */
  getDisplay(displayedColumns: string[], offsetWidth: number): string[] {
    try {
      if (this.isMobile && offsetWidth <= 480) {
        return displayedColumns.slice(0, 2);
      }

      return displayedColumns;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDisplay', error.message);
    }

    return [];
  }
}
