import { IProgress } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IProgressState } from './progress-state';

import * as ProgressActions from './progress.actions';

export const progressesFeatureKey = 'progress';


export const adapterProgress: EntityAdapter<IProgress> = createEntityAdapter<IProgress>({
  selectId: instance => instance.progressIdentifier,
  sortComparer: sortOnDateTime
});

export const initialStateProgress: IProgressState = adapterProgress.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedIdentifier: null,
});


function sortOnDateTime(a: IProgress, b: IProgress): 1 | -1 | 0 {
  if (a.time < b.time) {
    return -1;
  }
  if (a.time > b.time) {
    return 1;
  }
  return 0;
}

export const reducer = createReducer(
  initialStateProgress,
  on(ProgressActions.addProgress,
    (state, action) => adapterProgress.addOne(action.progress, state)
  ),
  on(ProgressActions.setProgress,
    (state, action) => ({ ...state, selectedId: action.selectedId })
  ),
  on(ProgressActions.upsertProgress,
    (state, action) => adapterProgress.upsertOne(action.progress, state)
  ),
  on(ProgressActions.addProgresses,
    (state, action) => adapterProgress.addMany(action.progresses, state)
  ),
  on(ProgressActions.upsertProgresses,
    (state, action) => adapterProgress.upsertMany(action.progresses, state)
  ),
  on(ProgressActions.upsertUnscheduledProgress,
    (state, action) => adapterProgress.upsertOne(action.progress, state)
  ),
  on(ProgressActions.updateProgress,
    (state, action) => adapterProgress.updateOne(action.progress, state)
  ),
  on(ProgressActions.updateProgresses,
    (state, action) => adapterProgress.updateMany(action.progresses, state)
  ),
  on(ProgressActions.deleteProgress,
    (state, action) => adapterProgress.removeOne(action.id, state)
  ),
  on(ProgressActions.deleteProgresses,
    (state, action) => adapterProgress.removeMany(action.ids, state)
  ),

  on(ProgressActions.loadProgressSuccess,
    (state, action) => {
      window.history.back();
      return adapterProgress.setOne(action.progress, state)
    }
  ),

  on(ProgressActions.loadProgressesSuccess,
    (state, action) => adapterProgress.setAll(action.progresses, state)
  ),
  on(ProgressActions.progressFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(ProgressActions.clearProgresses,
    state => initialStateProgress
  ),
  on(ProgressActions.clearSelectedProgress,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(ProgressActions.loadProgresses,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterProgress.getSelectors();


export const getSelectedId = (state: IProgress) => state.progressIdentifier;
