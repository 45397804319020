import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigJsonService } from './config-json.service';
import { GlobalService } from './global.service';
import { routePostLog } from './api-routes';
import { UnsubscribeOnDestroyAdapter } from './unsubscribe-ondestroy.adapter';

/**
 * @classdesc Classe que centralizará os registros de Log.
 * Foi criada como um serviço injetável para permitir uma possível
 * utilização de herança, uma vez que com métodos estáticos isso não seria possível.
 */
@Injectable({ providedIn: 'root' })
export class LogService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private http?: HttpClient,
    private cnf?: ConfigJsonService,
    private glb?: GlobalService
  ) {
    super();
  }

  protected static lastLog: LogService = null;
  public static UsuarioLogadoNo = -1;
  public static ShowLogMessage = false;
  public static ShowConsoleLog = true;
  public static PersistLog = true;
  protected data: Date = null;
  protected classe = '';
  protected metodo = '';
  protected usuarioNo = -1;
  protected descricao = '';
  private url = routePostLog;

  /**
   * Registra um log
   * @param classe Nome da classe em que ocorreu o erro
   * @param metodo Nome do método
   * @param descricao Descrição do log
   * @param usuarioNo
   */
  Registrar(
    classe: string,
    metodo: string,
    descricao: string,
    usuarioNo?: number
  ): void {

    this.cnf.logCount++;

    // TODO: Remover ou aumentar expressivamente o volume.
    if (this.cnf.logCount > 100) {
      return;
    }

    const msg: string =
      'Classe: ' +
      classe +
      ' Método: ' +
      metodo +
      ' Descrição: ' +
      descricao +
      ' usuarioNo: ' +
      usuarioNo;

    try {
      this.classe = classe;
      this.data = new Date();
      this.descricao = descricao;
      this.metodo = metodo;
      this.usuarioNo = usuarioNo || LogService.UsuarioLogadoNo;

      LogService.lastLog = this;

      if (LogService.ShowLogMessage) {
        this.showMessage(msg);
      }

      if (LogService.ShowConsoleLog) {
        console.log('ApiLogService.showMessage:', msg);
      }

      if (LogService.PersistLog) {
        this.Salvar();
      }
    } catch (error) {
      console.log(msg + ' Log: ' + error.message);
    }
  }

  protected showMessage(msg: string): void {
    alert(msg);
  }

  /**
   * Consumirá um serviço para carregamento dos dados
   */
  public Salvar(): void {
    try {
      const data = {
        class: this.classe,
        message: this.descricao,
        method: this.metodo,
        usuarioNo: this.usuarioNo,
      };

      if (!this.http) {
        console.log('LogService: ', data);
      } else {
        try {
          this.subs.sink = this.http
          .post(this.getUrl(), { data })
          .subscribe(() => {
            console.log('Log Salvo com sucesso');
          });
        } catch (error) {
          console.log(' Log: ' + error.message);
        }
       
      }
    } catch (error) {
      this.showMessage(' Log: ' + error.message);
    }
  }

  private getUrl(): string {
    try {
      if (this.glb.IsNullOrEmpty(this.url)) {
        throw new Error('Url não definida na classe filha');
      }
      return `${this.cnf.baseUrlAPI}${this.url}`;
    } catch (error) {
      console.log(' Log: ' + error.message);
    }
    return null;
  }
}
