<!-- <p-messages [value]="msgs" [style]="{'background-color': 'white', 'color': 'black'}"></p-messages> -->
<div *ngIf="formErrors && cnfJson.showFormErrors" class="alert alert-danger">
  {{ formErrors | json }}
</div>


<div *ngIf="atividade" class="grid" [class.isMobile]="isMobile" [class.show-back]="showBackButton" [class.black]="enTheme === 'black'">

  <button *ngIf="showBackButton" mat-button class="btn btn-back" (click)="onBack($event)" type="button" >
    <mat-icon>arrow_back_ios</mat-icon>VOLTAR
  </button>

  <div *ngIf="hasPrint" class="print">
    <button mat-flat-button [class.btn-mobile]="isMobile" type="button" (click)="onPrint($event)">
      <mat-icon>print</mat-icon>
    </button>
  </div>

  <div *ngIf="hasAttachment" class="file">
    <button mat-flat-button [class.btn-mobile]="isMobile" type="button" (click)="onAttachment($event)"
      [matBadge]="attachmentCount" matBadgePosition="before" matBadgeColor="accent">
      <mat-icon>attach_file</mat-icon>
    </button>
  </div>

  <div *ngIf="hasDelete" class="delete">
    <button mat-flat-button [class.btn-mobile]="isMobile" color="warn" type="button" (click)="onDelete($event)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

</div>
