<div
  [formGroup]="formGroup"
  class="form-group"
  [class.has-error]="!isValid(formGroup, ctrl)"
  [libCtrConfigUI]="ctrl"
  [altura]="ctrl?.Altura"
  [isMobile]="isMobile"
>
  <lib-title-label
    [formGroup]="formGroup"
    [isMobile]="isMobile"
    [enTheme]="enTheme"
    [ctrl]="ctrl"
    #lblTitle
  >
  </lib-title-label>

  <div [ngSwitch]="isCompactVersion" class="ctrl">
    <ng-container *ngSwitchCase="true">
      <button mat-icon-button type="button" (click)="onAmpliar_Click($event)">
        <mat-icon>search</mat-icon> <span>Ampliar</span>
      </button>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container [ngSwitch]="isHtml">
        <ng-container *ngSwitchCase="true">
          <span
            class="form-group"
            [class.has-content]="hasContent"
            [class.black]="enTheme === 'black'"
            [tabIndex]="getTabIndex(ctrl)"
            [libCtrConfigUI]="ctrl"
            [altura]="ctrl?.Altura"
            [isMobile]="isMobile"
            [innerHTML]="ctrl?.ValorTexto"
          ></span>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <!--Carregado se NÃO for Editor Html-->
          <textarea
            [id]="getId(ctrl)"
            pInputTextarea
            class="form-group"
            [class.has-content]="hasContent"
            [class.black]="enTheme === 'black'"
            [tabIndex]="getTabIndex(ctrl)"
            [formControlName]="getId(ctrl)"
            [libCtrConfigUI]="ctrl"
            [altura]="ctrl?.Altura"
            [isMobile]="isMobile"
            [placeholder]="ctrl?.ToolTipMessage || ''"
            style="font-size: 11px"
          ></textarea>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
