<div
  [formGroup]="formGroup"
  class="form-group"
  [class.has-error]="!isValid(formGroup, ctrl)"
  [libCtrConfigUI]="ctrl"
  [altura]="ctrl?.Altura"
  [isMobile]="isMobile"
>
  <mat-spinner [diameter]="18" *ngIf="isLoading"></mat-spinner>

  <div *ngIf="!isLoading" class="input-group" [class.is-mobile]="isMobile">
    <ng-container [ngSwitch]="isSearchMode">
      <mat-form-field
        *ngSwitchCase="true"
        [id]="'formField' + getId(ctrl)"
        [class.full-width]="isMobile"
        [class.is-desktop]="!isMobile"
        [class.black]="enTheme === ENTHEME.black"
        [style.width.%]="100"
      >
        <input
          type="text"
          [placeholder]="ctrl?.ShowRotulo ? ctrl?.Rotulo : ''"
          aria-label="Number"
          matInput
          [formControl]="getFormControlFrom(ctrl)"
          [matAutocomplete]="auto"
          [tabIndex]="getTabIndex(ctrl)"
          #dropdown
          [libCtrMobileConfigUI]="ctrl"
          (keydown.esc)="resetValue(ctrl)"
          [required]="ctrl?.RequiredField"
        />

        <mat-autocomplete
          [id]="'auto_' + getId(ctrl)"
          autoActiveFirstOption
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let item of filteredOptions$ | async"
            [value]="item"
          >
            {{ item }}
          </mat-option>
        </mat-autocomplete>

        <mat-error
          *ngIf="
            !getFormControlFrom(ctrl).hasError('forceSelection') &&
            getFormControlFrom(ctrl).hasError('required')
          "
        >
          Opção obrigatória!</mat-error
        >

        <mat-error
          *ngIf="
            getFormControlFrom(ctrl).hasError('forceSelection') &&
            !getFormControlFrom(ctrl).hasError('required')
          "
        >
          Selecione uma das opções existentes!
        </mat-error>
      </mat-form-field>

      <ng-container *ngSwitchDefault>
        <mat-form-field
          appearance="fill"
          style="width: 100% !important"
          [id]="'formField' + getId(ctrl)"
          [class.full-width]="isMobile"
          [class.is-desktop]="!isMobile"
          [class.black]="enTheme === ENTHEME.black"
        >
          <mat-label *ngIf="ctrl?.ShowRotulo">{{ ctrl?.Rotulo }}</mat-label>

          <select
            title="Opções"
            [id]="'select_' + getId(ctrl)"
            matNativeControl
            [required]="ctrl?.RequiredField"
            [formControlName]="getId(ctrl)"
            [tabIndex]="getTabIndex(ctrl)"
            #dropdown
            [libCtrMobileConfigUI]="ctrl"
            class="select-combobox"
            [class.black]="enTheme === ENTHEME.black"
          >
            <option *ngFor="let item of items" [value]="item?.label">
              {{ item?.label }}
            </option>
          </select>

          <mat-error *ngIf="getFormControlFrom(ctrl).hasError('required')"
            >Opção obrigatória!</mat-error
          >
        </mat-form-field>
      </ng-container>
    </ng-container>

    <!--Menu de opções -->
    <div
      class="input-group-btn"
      *ngIf="showMenu(ctrl, dropdown || null)"
      [class.open]="isMenuOpened"
      [class.is-desktop]="!isMobile"
    >
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Opções"
        type="button"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu" [class.black]="enTheme === ENTHEME.black">
        <button
          mat-menu-item
          *ngIf="canCreate(ctrl)"
          type="button"
          [class.black]="enTheme === ENTHEME.black"
          (click)="
            onNewItem(
              $event,
              ctrl?.AtividadeNo,
              ctrl?.CadastroNo,
              ctrl?.VariavelNo,
              ctrl?.CanAddInCadastro
            )
          "
        >
          <mat-icon>add</mat-icon>
          <span>Novo</span>
        </button>

        <button
          mat-menu-item
          *ngIf="canEdit(ctrl, dropdown || null)"
          type="button"
          [class.black]="enTheme === ENTHEME.black"
          (click)="
            onEditSelected(
              $event,
              ctrl?.AtividadeNo,
              ctrl?.CadastroNo,
              dropdown || null,
              ctrl,
              isReadOnly(ctrl),
              ctrl?.CanAddInCadastro
            )
          "
        >
          <mat-icon>edit</mat-icon>
          <span>Editar</span>
        </button>

        <button
          mat-menu-item
          *ngIf="canDelete(ctrl, dropdown || null)"
          type="button"
          [class.black]="enTheme === ENTHEME.black"
          (click)="
            onDeleteItem(
              $event,
              ctrl?.AtividadeCadastroNo,
              dropdown || null,
              ctrl?.CanAddInCadastro
            )
          "
        >
          <mat-icon>delete</mat-icon>
          <span>Excluir</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
