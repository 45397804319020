<div class="container">
  <div class="top-bar">
    <button
      mat-button
      type="button"
      class="btn btn-back"
      (click)="onCloseClick($event)"
    >
      <mat-icon class="icn-back" style="align-content: center"
        >arrow_back_ios</mat-icon
      >VOLTAR
    </button>
  </div>

  <ng-container [ngSwitch]="fileUploadMode">
    <div class="single" *ngSwitchCase="ENFILEUPLOADMODE.single">
      <lib-cmp-file-upload-selector
        (afterFileUpload)="afterFileUpload($event)"
        (afterOpenWebcam)="afterOpenFile($event)"
        (afterChooseFile)="afterOpenFile($event)"
        [data]="data"
        [enFileUploadMode]="fileUploadMode"
      >
      </lib-cmp-file-upload-selector>
    </div>

    <!-- FILE LIST AND UPLOAD OPTIONS -->
    <div class="file-list" *ngSwitchDefault>
      <lib-cmp-file-upload-selector
        (afterFileUpload)="afterFileUpload($event)"
        (afterOpenWebcam)="afterOpenFile($event)"
        (afterChooseFile)="afterOpenFile($event)"
        [data]="data"
        [enFileUploadMode]="fileUploadMode"
      >
      </lib-cmp-file-upload-selector>

      <div class="table" *ngIf="!hideList">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.DocumentoNo }}
            </td>
          </ng-container>

          <ng-container matColumnDef="documento">
            <th mat-header-cell *matHeaderCellDef>Documento</th>
            <td mat-cell *matCellDef="let element">
              {{ element.DocumentoNomeExibicao }}
            </td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon>{{ extractIconName(element) }} </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="tamanho">
            <th mat-header-cell *matHeaderCellDef>Tamanho</th>
            <td mat-cell *matCellDef="let element">
              {{ element.Tamanho }}
            </td>
          </ng-container>

          <ng-container matColumnDef="versao">
            <th mat-header-cell *matHeaderCellDef>Versão</th>
            <td mat-cell *matCellDef="let element">
              {{ element.Versao }}
            </td>
          </ng-container>

          <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td class="hud-btns-td" mat-cell *matCellDef="let element">
              <button
                class="btn btn-primary"
                type="button"
                *ngIf="canPreview(element)"
                (click)="onPreview(element)"
              >
                Visualizar
              </button>

              <button
                class="btn btn-primary"
                type="button"
                (click)="onDownload(element)"
              >
                <mat-icon>download</mat-icon>
              </button>

              <button
                class="btn btn-danger"
                type="button"
                (click)="onDelete(element)"
              >
                <mat-icon class="mat-18">delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="noRecords">
            <td mat-footer-cell *matFooterCellDef>
              Nenhum documento encontrado.
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="
              dataSource && dataSource.data.length ? displayedColumns : []
            "
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="
              dataSource && dataSource.data.length ? [] : ['noRecords']
            "
            colspan="6"
          ></tr>
        </table>
      </div>
    </div>

    <div class="img-view" *ngSwitchCase="ENFILEUPLOADMODE.preview">
      <p style="text-align: center" class="nome-foto">
        {{ selectedDocument.DocumentoNomeExibicao }}
      </p>

      <img
        [style.height]="600 + 'px'"
        [src]="selectedDocument.CaminhoFisico"
        [alt]="selectedDocument.DocumentoNomeExibicao"
      />

      <div class="hub-btns">
        <button
          style="margin-right: 8px"
          class="btn btn-primary"
          type="button"
          (click)="onPreviewBack($event)"
        >
          Fechar
        </button>

        <button
          style="margin-right: 8px"
          class="btn btn-primary"
          type="button"
          (click)="onDownload(selectedDocument)"
        >
          Baixar
        </button>

        <button
          class="btn btn-danger"
          type="button"
          (click)="onDelete(selectedDocument)"
        >
          Excluir
        </button>
      </div>
    </div>
  </ng-container>
</div>
