<ng-container [ngSwitch]="enFileUploadMode">

  <!-- WEBCAM CAPTURE -->
  <ng-container *ngSwitchCase="'webcam'">
    <lib-cmp-webcam (dadosOut)="uploadFileFromWebcam($event)" (back)="onPreviewBack($event)"></lib-cmp-webcam>
  </ng-container>

  <ng-container *ngSwitchDefault>

    <div class="upload-options" *ngIf="!isLoading">



        <div class="hud-botoes">
          <button mat-button class="custom-camera-button" (click)="onWebcamClick($event)" type="button">
            <mat-icon>photo_camera</mat-icon>
            <span>Usar Câmera</span>
          </button>
          <input type="file" id="fileInput" (change)="onImgChange($event)" [accept]="acceptFiles" style="display: none;">
            <label for="fileInput" class="custom-file-upload">
              Escolher arquivo
            </label>
        </div>

        <div class="cropper-container">
        <div class="cropper" *ngIf="fileEvent" style="align-content: center;">
          <image-cropper style="max-height: 700px;" 
            [imageChangedEvent]="fileEvent" 
            [maintainAspectRatio]="true" 
            [aspectRatio]="220 / 290"
            [resizeToWidth]="220" 
            format="png" 
            (imageLoaded)="showCropper()"
            (cropperReady)="invokeCropper()"       
            (imageCropped)="imageCrop($event)" 
            (loadImageFailed)="error()">
          </image-cropper>
        </div>
        <div class="previewImg" *ngIf="preview">
          <p><strong>Pré-visualização da imagem cortada</strong></p>
          <img [src]="preview" />
          <button class="botaoUpCropper" mat-button (click)="uploadFile($event)" type="button">
            <mat-icon>cloud_upload</mat-icon>
            <span>UPLOAD</span>
          </button>
        </div>
      </div>
      
      

    </div>

    <mat-progress-bar mode="determinate" *ngIf="fileToUpload && progress > 0 && progress <= 100" [value]="progress">
    </mat-progress-bar>

    <ui-loading [isLoading]="isLoading"></ui-loading>

  </ng-container>