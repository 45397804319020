
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IEvolucaoFono, LocalLibService } from '@medlogic/shared/shared-interfaces';
import {
  map, publishReplay, refCount, filter,
  toArray,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { CadastroService, ModelComponent } from '@medlogic/shared/shared-data-access';
import { EvolucaoFonoService } from './evolucao-fono.service';

@Injectable({
  providedIn: 'root'
})
export class EvolucaoFonoCustomService extends EvolucaoFonoService {

  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    log: LogService,
    protected lib: LocalLibService,
    protected modelComponent: ModelComponent,
  ) {
    super(http, cadastroSrv, glb, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna todos os itens dos sinais vitais através do id do paciente. */
  getByIdAndPeriod(ano: number, patientId: string, dtStart: Date = null, dtEnd: Date = null, lstVariaveis?: string): Observable<any> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filterStr = `V_28051:${patientId}`;

      return this.getFiltered(this.cadastroNo, filterStr, startDate, endDate, lstVariaveis).pipe(
        toArray(),
        map((evolutions: IEvolucaoFono[]) => {
          let currentEvo = evolutions[0] as IEvolucaoFono;
          for (let i = 0; i < evolutions.length; i += 1) {
            const date = evolutions[i].dtEvolucao;
            if (currentEvo.dtEvolucao < date) {
              currentEvo = evolutions[i];
            }
          }
          return currentEvo
        }),
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriod', error.message);
    }
    return of(null);
  }

  /**
   * Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`.
   * Também filtra especificamente a dataavaliacao dentro do período.
   */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date, lstVariables?: string): Observable<IEvolucaoFono> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);

      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables || this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          filter((f: IEvolucaoFono) => this.glb.isBetweenIgnoreTime(f.dtEvolucao, startDate, endDate)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  sortByDate = () => map((v: IEvolucaoFono[]) =>
    v.sort(
      (a, b) =>
        b.dtEvolucao && a.dtEvolucao
          ? this.glb.compareDates(a.dtEvolucao, b.dtEvolucao)
          : 0
    )
  )

  /* OBS: Usado apenas pelo card-prescription. */
  getFromCadastroFiltro(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);

      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, false, startDate, endDate)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  /* Check se está fora da referência. */
  protected isOutOfRef(value: number, minRef: number, maxRef: number): boolean {
    try {
      return value < minRef || value > maxRef;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isOutOfRef', error.message);
    }
    return false;
  }

  mapObjToEvolucaoFono(obj: { [key: string]: string | Date | number }): Observable<IEvolucaoFono> {
    try {
      return of(obj).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapObjToVitalSign', error.message);
    }
    return of(null);
  }

  private mapTo = () => map((c: any) => this.toAttribute(c));
}
