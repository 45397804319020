import { clearPatients } from '@medlogic/medlogic/medlogic-state';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import {
  isPatientLoading,
  clearSelectedPatient,
  selectedPatient,
  setPatient,
  loadPatientsWithMedications,
  isMedicationLoading,
  selectPatientAllActive,
  clearMedications,
} from '@medlogic/medlogic/medlogic-state';
import { ActivatedRoute } from '@angular/router';
import { IMonth } from '../../../assist/interface/imonth';
import {
  IMedication,
  ConfigJsonService,
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IResponsibleFamily } from '../../interface/iresponsible-family';
import { CardPatientProvider } from '../../provider/card-patient.provider';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { LocalMsgPtBR } from '../../../module/core/service/local-msg-ptBR.service';
import { HomeView, CustomerConfigService } from '@medlogic/shared/gecore';
import { Store, select } from '@ngrx/store';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import {
  Observable,
  BehaviorSubject,
  map,
  tap,
  take,
  of,
  catchError,
} from 'rxjs';

@Component({
  selector: 'ml-card-patient',
  templateUrl: './card-patient.component.html',
  styleUrls: ['./card-patient.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPatientComponent extends HomeView implements OnInit {
  patients$: Observable<IPatient[]> = this.store.select(selectPatientAllActive);
  selectedPatient$: Observable<IPatient> = this.store.select(selectedPatient);
  isLoading$: Observable<boolean> = this.store.select(isPatientLoading);
  isLoadingMedications$: Observable<boolean> =
    this.store.select(isMedicationLoading);

  lstConfig: any[] = [];
  medication = {} as IMedication; // armazena a medicação selecionada
  months: IMonth[];
  selectedMonth: any = 0;
  weekday: string;
  today: string;
  keyword = '';
  currentTime$ = new BehaviorSubject<Date>(new Date());
  isAction = false;
  cadPacienteNo = -1;
  cadVitalSignsNo = -1;
  cadIntervecoesMedicamentosNo = -1;
  cadIntervecoesHorariosNo = -1;
  cadCheckMedicamentosNo = -1;
  cadResponsavelFamiliaNo = -1;
  cadCheckVitalSignsNo: number;
  cadEvolutionNo: number;
  cadEstoqueMateriaisNo: number;
  cadCareRoutineNo: number;
  cadCheckinCareRoutineNo: number;
  resFamily: IResponsibleFamily[] = new Array<IResponsibleFamily>();

  showTime = true;
  showMiniImg = false; // Mostra as fotos em tamanho menor. OBS: Está desativado, pois, com a nova disposição as imagens já são pequenas.

  showVitalSigns = false;
  showPatientReport = false;
  showMedicationCheckin = false;
  showDailyEvaluation = true;
  showCarePlan = true;
  showCustomModules = false;
  showCarePlanReport = true;
  showPatient = false;

  @ViewChild('modalDialog', { static: true }) modalDialog;

  public get currentMonth(): IMonth {
    try {
      if (this.months !== undefined) {
        return this.months.find((f) => f.value === this.selectedMonth);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'currentMonth', error.message);
    }
    return null;
  }

  customerId$: Observable<number> = this.store.pipe(
    select((state) => state.tenant.login.customerId),
    take(1)
  );

  constructor(
    log: LogService,
    glb: GlobalService,
    cnf: ConfigPwaMedLogicService,
    route: ActivatedRoute,
    modelComponent: ModelComponent,
    customCnf: CustomerConfigService,
    msg: LocalMsgPtBR,
    public pv: CardPatientProvider,
    private cnfJson: ConfigJsonService,
    protected nav: NavigationPwaService,
    private store: Store<IAppMedlogicState>
  ) {
    super(log, glb, cnf, route, nav, modelComponent, customCnf, msg);
    this.store.dispatch(clearMedications());
    this.store.dispatch(loadPatientsWithMedications());
  }

  ngOnInit(): void {
    try {
      if (selectedPatient) {
        this.showPatient = true;
      }

      const permissions = [
        'showVitalSigns',
        'showPatientReport',
        'showMedicationCheckin',
        'showDailyEvaluation',
        'showCarePlan',
        'showCustomModules',
        'showCarePlanReport',
      ];
      for (const permission of permissions) this.getPermissions(permission);

      this.nav.addToHistory(
        this.route.snapshot.url.map((m) => m.path),
        '[carecard] CardPatient'
      );
      this.refresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  refreshClick($event: any): void {
    try {
      this.store.dispatch(clearPatients());
      this.store.dispatch(clearMedications());
      this.store.dispatch(loadPatientsWithMedications());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refreshClick', error.message);
    }
  }

  protected setCadParams(): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  protected setCurrentRoute(): void {
    try {
      this.currentRoute = this.nav.getRouteCardPatient(this.cnf.modeloConfigNo);
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'setCurrentRoute',
        error.message
      );
    }
  }

  /** Carrega os campos na tela. Carregará, além de todos os pacientes,
   * suas respectivas medicações. O card de medication receberá, portanto,
   * esse dado já pronto.
   */
  protected refresh():
  void {
    try {
      const today = new Date();
      this.weekday = `${this.glb.DiaDaSemana(today.getDay())}`;
      this.today = `${today.getDate()} de ${this.glb.NomeDoMes(
        today
      )} de ${today.getFullYear()}`;
      this.setTimer();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  showDialog(medication: IMedication, patient: IPatient): void {
    try {
      if (!medication.took) {
        medication.medicationDate = new Date();
        this.modalDialog.displayDialog = !this.modalDialog.displayDialog;
        this.medication = medication;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDialog', error.message);
    }
  }

  /* Ativa o relógio */
  protected setTimer(): void {
    try {
      setInterval(() => {
        this.currentTime$.next(new Date());
      }, 1000);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setTimer', error.message);
    }
  }

  /**
   * Clique no input de pesquisa.
   * Torna invisível os elementos desnecessários.
   */
  onSearchClick(event: any): void {
    try {
      this.gotoPatientsList();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSearchClick', error.message);
    }
  }

  /* Retorna para a página de lista de pacientes. */
  protected gotoPatientsList(): void {
    try {
      this.store.dispatch(clearSelectedPatient());
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'gotoPatientsList',
        error.message
      );
    }
  }

  /* Limpará a busca e restaurará o tamanho grande. */
  onClearSearch(): void {
    try {
      this.keyword = '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClearSearch', error.message);
    }
  }

  /**
   * Perda de foco no input de pesquisa.
   * Torna invisível os elementos desnecessários.
   */
  onBlurClick($event: any): void {
    try {
      if (!this.glb.isNullOrEmpty(this.keyword)) {
        this.gotoPatientsList();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBlurClick', error.message);
    }
  }

  /**
   * Irá esconder/exibir os elementos visuais acessórios para ampliar o espaço para o resultado de busca.
   * Mas caso esteja restabelecendo, terá um pequeno delay para que não impeça um click num botão devido a mudar o botão de posição.
   */
  protected toogleVisualElements(isVisible: boolean): void {
    try {
      if (isVisible) {
        setTimeout(() => {
          this.cnf.showMenu = true;
        }, 500);
      } else {
        this.cnf.showMenu = true;
      }
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'toogleVisualElements',
        error.message
      );
    }
  }

  /* Paciente selecionado. */
  onPatientClick(patient: IPatient): void {
    try {
      this.showPatient = true;

      if (patient) {
        this.store.dispatch(setPatient({ selectedId: +patient.codigo }));
        this.isAction = true;
      }
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'onPatientClick',
        error.message
      );
    }
  }

  /* Click no voltar das ações para a lista de pacientes. */
  onActionBackClick(): void {
    try {
      this.isAction = false;
      this.store.dispatch(clearSelectedPatient());
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'onActionBackClick',
        error.message
      );
    }
  }

  onActionBackToList(): void {
    this.showPatient = false;
  }

  private getPermissions(permission: string) {
    try {
      const { [permission]: showPermission, toClients } =
        this.cnfJson.getConfigs(permission);

      this.subs.sink = this.customerId$
        .pipe(
          map((id) => {
            switch (showPermission) {
              case true:
                toClients.includes(id)
                  ? (this[permission] = true)
                  : (this[permission] = false);
                break;

              case false:
                toClients.includes(id)
                  ? (this[permission] = false)
                  : (this[permission] = true);
                break;
            }
          }),
          catchError((error) => {
            console.log(
              this.constructor.name,
              'getPermissions.' + permission,
              error.message
            );
            return of(null);
          })
        )
        .subscribe();
    } catch (_error: any) {
      console.log(
        this.constructor.name,
        'getPermissions.' + permission,
        _error.message
      );
    }
  }
}
