import { clearPatients, setIsLoading, StatesManagementService } from '@medlogic/medlogic/medlogic-state';
import {
  PatientCustomService, EvolutionCustomService,
  EstoqueMateriaisCustomServices,
  FamilyCustomService
} from '@medlogic/medlogic/medlogic-data-access';
import { Injectable } from '@angular/core';
import {
  CadConfigService, CustomerConfigService
} from '@medlogic/shared/gecore';
import { LocalMsgPtBR } from './local-msg-ptBR.service';
import { IntervencoesMedicamentosHorariosService } from '@medlogic/medlogic/medlogic-data-access';
import { MedicationCheckinCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { InterventionMedicationCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { CardPatientProvider } from '../../../pwa/provider/card-patient.provider';
import { LocalStorageService } from './local-storage.service';
import { EnRequestType, LogService, routeGenerateRegistrationOptions, routeGetPublicKey, routeRegisterBiometrics, routeVerifyRegistrationResponse, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { NavigationService } from '../../../pwa/service/navigation.service';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Store } from '@ngrx/store';
import { clearLogin } from '../ngrx/actions/login.actions';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocalLoginService extends UnsubscribeOnDestroyAdapter {

  urlPost = 'Account/Login';
  urlPostLoginWithUser = 'Account/LoginWithUser';

  constructor(
    protected basepageService: BasePageService,
    protected cnf: ConfigPwaMedLogicService,
    protected log: LogService,
    protected nav: NavigationService,
    protected cad: CadConfigService,
    protected msg: LocalMsgPtBR,
    protected horPersonSrv: IntervencoesMedicamentosHorariosService,
    protected checkMedSrv: MedicationCheckinCustomService,
    private patientSrv: PatientCustomService,
    private intervecoesMedicamentosSrv: InterventionMedicationCustomService,
    private stockSrv: EstoqueMateriaisCustomServices,
    private evolutionSrv: EvolutionCustomService,
    private custConfigSrv: CustomerConfigService,
    private pv: CardPatientProvider,
    private localStorageSrv: LocalStorageService,
    private store: Store<IAppMedlogicState>,
    private familySrv: FamilyCustomService,
    private stateManSrv: StatesManagementService,
  ) {
    super();
  }

  // BIOMETRICS
  generateRegistrationOptions(loggedInUserId: string): Observable<any> {
    try {
      return this.basepageService.baseDados(EnRequestType.Get, routeGenerateRegistrationOptions + `/?loggedInUserId=${loggedInUserId}`, null);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
  }

  getPublicKey(userName: string): Observable<CredentialCreationOptions> {
    try {
      return this.basepageService.baseDados(EnRequestType.Get, routeGetPublicKey + `/?userName=${userName}`, null);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getPublicKey', error.message);
    }
  }

  verifyRegistrationResponse(loggedInUserId: string, credentials: any) {
    try {
      return this.basepageService.baseDados(EnRequestType.Post, routeVerifyRegistrationResponse + `/?loggedInUserId=${loggedInUserId}`, credentials);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'postCredentials', error.message);
    }
  }

  postCredentials(userName: string, credentials: Credential) {
    try {
      return this.basepageService.registerBiometrics(routeRegisterBiometrics + `/?userName=${userName}`, credentials);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'postCredentials', error.message);
    }
  }

  /* override */
  logoff(isLoading: boolean = false): void {
    try {
      this.cnf.reset();
      this.localStorageSrv.clean();
      this.cleanAllCaches(isLoading);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'logoff', error.message);
    }
  }

  /**
   * Limpará todos os caches. Deverá navegar para a página inicial novamente, para recarregar os contadores.
  */
  cleanAllCaches(isLoading: boolean = false): void {
    try {
      this.cleanStates(isLoading);
      this.intervecoesMedicamentosSrv.clearCache();
      this.patientSrv.clearCache();
      this.stockSrv.clearCache();
      this.horPersonSrv.clearCache();
      this.checkMedSrv.clearCache();
      this.evolutionSrv.clearCache();
      this.custConfigSrv.clearCache();
      this.familySrv.clearCache();
      this.pv.clearCache();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllCaches', error.message);
    }
  }

  cleanStates(isLoading: boolean = false): void {
    try {
      this.subs.sink = this.stateManSrv.cleanAllStatesAndCache$()
        .subscribe(() => {
          this.store.dispatch(setIsLoading({ isLoading }));
          this.store.dispatch(clearLogin());
          this.store.dispatch(clearPatients());
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanStates', error.message);
    }
  }

  /**
   * Efetuar login. Retorna o token do usuário se bem sucedido.
   * Já gravará o token na propriedade de configurações.
   * Também gravará o id do idoso bem cuidado.
   */
  getLogin(Login: string, Password: string): Observable<any> {
    try {
      const credentials = { Password, Login };
      const login = this.basepageService.baseDados(EnRequestType.Post, this.urlPost, credentials);

      return new Observable(observer => {
        this.subs.sink = login.subscribe(token => {
          try {
            if (token) {
              this.cnf.baseUsuarioToken = token;
              this.cnf.showMenu = true;

              this.subs.sink = this.cad.getCadIdosoBemCuidado()
                .subscribe(atividade => {
                  this.cnf.tenantId = atividade.AtividadeNo;
                  observer.next({ token, tenantId: atividade.AtividadeNo });
                  observer.complete();
                });
            } else {
              observer.next({ token: null, message: 'Unauthorized', msg: this.msg.LOGIN_FAIL });
              observer.complete();
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'getLogin.subscribe', error.message);
          }
        });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLogin', error.message);
    }
    return of(null);
  }

  /**
   * Semelhante ao getLogin, no entanto também retorna o usuarioNo, além do token.
   * Atenção: os parâmetros token e usuarioLogadoNo já serão armazenados no config.service.
   */
  getLoginWithUser(userName: string, password: string): Observable<any> {
    try {
      const login = { password, userName };

      return this.basepageService.baseDados(EnRequestType.Post, this.urlPostLoginWithUser, login)
        .pipe(
          mergeMap(login => {
            if (login && !login.message) {
              this.cnf.baseUsuarioToken = login?.UsuarioToken;
              this.cnf.usuarioLogadoNo = login?.UsuarioNo;

              return of({
                token: login?.UsuarioToken,
                usuarioLogadoNo: login?.UsuarioNo,
                role: login?.roles,
                imgUrl: login?.imgUrl,
                customerId: login?.customerId
              });
            } else
              return of({ token: null, message: 'Unauthorized', msg: this.msg.LOGIN_FAIL });
          }),
          catchError(error => {
            this.log.Registrar(this.constructor.name, 'getLoginWithUser.baseDados', error.message);
            return of({ token: null, message: 'Unauthorized', msg: this.msg.LOGIN_FAIL });
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLoginWithUser', error.message);
    }
    return of(null);
  }
}
